<!--  基于element-ui表格 ItvElTable hw-table

ItvElTable 封装 

#逻辑1: 支持传入[表格列配置][columns]，代替手动写很多的[el-table-column]标签 (借鉴[ant])
#逻辑2: 兼容点击树形表格的父级行(第一行)，可以展开树表格
#逻辑3: 支持表头后缀气泡提示

 -->
<template>
  <el-table
    class="hw-table w100"
    :data="list"
    :rowKey="rowKey"
    :tree-props="treeProps"
    :default-expand-all="defaultExpandAll"
    @row-click="rowClick"
    ref="refElTable"
    :size="size"
  >
    <el-table-column
      :prop="item.dataIndex"
      :label="item.title"
      :width="item.width"
      :min-width="item.minWidth"
      :fixed="item.fixed"
      v-for="(item, index) in columns"
      :key="index"
    >
      <!-- #逻辑3 -->
      <template #header v-if="item.titleDetail">
        <span>{{ item.title }} </span>
        <ItvElTip :content="item.titleDetail" />
      </template>
      <!-- #逻辑2 -->
      <!-- 插槽: https://cn.vuejs.org/v2/guide/components-slots.html -->
      <template #default="{ row, column, $index }">
        <!-- item (自定义的列信息) column 是基于 item 生成的 -->
        <!-- column (el组件的列信息) 示例:
          (注意: 自定义 dataIndex 不在 column 中)
          column: {
            order: "",
            id: "el-table_1_column_1",
            type: "default",
            property: "name",
            align: null,
            headerAlign: null,
            showOverflowTooltip: false,
            filteredValue: [],
            filterPlacement: "",
            isColumnGroup: false,
            filterOpened: false,
            sortable: false,
            label: "姓名",
            resizable: true,
            sortOrders: ["ascending", "descending", null],
            reserveSelection: false,
            filterMultiple: true,
            minWidth: 80,
            realWidth: 507,
            level: 1,
            colSpan: 1,
            rowSpan: 1,
          },
         -->
        <slot
          name="bodyCell"
          v-bind:row="row"
          v-bind:column="column"
          v-bind:$index="$index"
          v-bind:dataIndex="item.dataIndex"
        >
          <ItvElTableCell :row="row" :item="item"></ItvElTableCell>
        </slot>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import mixinsItvElTable from "@/components/ItvElTable/mixinsItvElTable"
import ItvElTip from "@/components/ItvElTip.vue"
import ItvElTableCell from "@/components/ItvElTable/ItvElTableCell.vue"

// [ant-table](https://www.antdv.com/components/table-cn#components-table-demo-row-selection)
// [el-table](https://element.eleme.io/#/zh-CN/component/table)
export default {
  name: "ItvElTable",
  components: { ItvElTableCell, ItvElTip },
  mixins: [mixinsItvElTable],
  props: {
    /** 表格数据 */
    list: {
      type: Array,
      default() {
        return []
      },
    },
    /** [表格列配置]
     * @descrition 单元数据结构如下:
     * dataIndex: {String} 对应的list数据键名
     * title: {String} 列名
     * width: {String} 对应列的宽度
     * minWidth: {String} 对应列的最小宽度
     * fixed: {Boolean, String} 列是否固定,可选值[true, left, right]
     * tooltip: {Boolean} 气泡提示
     */
    columns: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /** [展开某行树表格] #逻辑2 */
    toggleRowExpansion(row) {
      // console.log("[toggleRowExpansion] refElTable")
      this.$refs.refElTable?.toggleRowExpansion?.(row)
    },
  },
}
</script>

<style scoped lang="less">
// .hw-table {
//   width: 100%;
// }
</style>
