<!--
 * @Date: 2022-05-13 12:54:24
 * @LastEditTime: 2022-08-10 16:35:52
-->
<!-- 下拉选项 ItvElSelect itv-el-select
实现:
  - 封装 options ，直接传入选项，少写html
  - 封装 readonly 只读模式
 -->
<template>
  <div class="itv-el-select dib">
    <!-- 注意: 必需[dib]，否则下拉框css宽度不正确 -->
    <el-select
      v-model="valueInner"
      v-if="!readonly"
      :placeholder="placeholder"
      :disabled="disabled || readonly"
      :filterable="canFilter"
      :remote="isRemoteFilter"
      :remote-method="doRemoteSearchDebounce"
      :loading="isDoing"
      :clearable="clearable"
      :multiple="multiple"
      @change="onOption_ItvElFormComponent"
      @click.native="onClick"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="Boolean(item.disabeld)"
      >
      </el-option>
    </el-select>
    <span v-else>{{ valueShow }}</span>
  </div>
</template>

<script>
import lodash from "lodash"
import {
  getValueLabelByOptions,
  mixins_ItvElFormItem_value, // value(:watch) valueInner
  mixins_ItvElFormItem_onOption, // onOption_ItvElFormComponent($input,$onChange)
} from "@/components/mixins/mixinsItvElInput"

export default {
  name: "ItvElSelect",
  components: {},
  mixins: [mixins_ItvElFormItem_value, mixins_ItvElFormItem_onOption],
  props: {
    /**
     * --- el组件支持的属性 ---
     * https://element.eleme.io/#/zh-CN/component/select
     * (为了简单化组件的[远程搜索]逻辑，不使用v-bind=$attrs)
     * 不支持[filterable:可过滤搜索][remote:是远程过滤搜索]
     */
    /** 远程搜索方法 */
    remoteMethod: {
      type: Function,
      default: null,
    },
    /** 占位符 */
    placeholder: {
      type: String,
      default: "",
    },
    /** 禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 可清楚 */
    clearable: {
      type: Boolean,
      default: false,
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
    /** 多选 */
    multiple: {
      type: Boolean,
      default: false,
    },

    // --- 组定义属性 ---
    /** [外部值] (覆盖[mixins_ItvElFormItem_value]中的[value]) */
    value: {
      type: [String, Number, Boolean, Array], // bugfix: Boolean 写在前面，默认值变成 true
      default: "",
    },
    /** 选项 {value:值, label:显示的名称, disabled:禁用} */
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDoing: false, // 操作中 (远程获取数据中)
      canFilter: false, // 可过滤搜索
      isRemoteFilter: false, // 是远程搜索
      doRemoteSearchDebounce: null, // 远程搜索的方法
    }
  },
  computed: {
    /** 选项值对应的文案 */
    valueShow() {
      if (this.readonly) {
        if (this.multiple) {
          return this.value
            .map((item) => {
              return getValueLabelByOptions({
                options: this.options,
                value: item,
              })
            })
            .join("、")
        }
        return getValueLabelByOptions({
          options: this.options,
          value: this.value,
        })
      }
      return ""
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.dealRemoteLogic()
  },
  methods: {
    /** 兼容:[只读]导致的[el-select]点击事件穿透 */
    onClick() {
      this.$emit("click")
    },
    /** 设置[远程搜索]逻辑
     * @description (只有传入[远程搜索方法][remoteMethod]才可过滤搜索)
     * 不支持[filterable:可过滤搜索]
     */
    dealRemoteLogic() {
      const isRemote = typeof this.remoteMethod === "function"
      this.canFilter = isRemote
      this.isRemoteFilter = isRemote
      this.doRemoteSearchDebounce = lodash.debounce(this.doRemoteSearch, 300) // 防抖
    },
    /** 执行[远程搜索] */
    async doRemoteSearch(query) {
      if (!this.remoteMethod) return
      this.isDoing = true
      await this.remoteMethod(query)
      this.isDoing = false
    },
  },
}
</script>
