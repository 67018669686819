<!--
 * @Date: 2022-05-19 13:03:48
 * @LastEditTime: 2022-08-10 14:10:51
-->
/* 带%号的输入框组件 HwInputRatio hw-input-radio */
<template>
  <div class="hw-input-radio">
    <el-input
      v-if="!readonly"
      v-model="valueInner"
      type="number"
      @input="onChange_ItvElFormComponent"
    >
      <template slot="append">%</template>
    </el-input>
    <span v-else>{{ valueInner }}%</span>
  </div>
</template>

<script>
import {
  mixins_ItvElFormItem_value, // value(:watch) valueInner
  mixins_ItvElFormItem_onChange, // onChange_ItvElFormComponent($emit:input)
} from "@/components/mixins/mixinsItvElInput"

export default {
  name: "HwInputRatio",
  components: {},
  mixins: [mixins_ItvElFormItem_value, mixins_ItvElFormItem_onChange],
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.hw-input-radio {
//}
</style>
