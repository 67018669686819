import { showBug } from "../itv-js-el"
import {
  doGetSignEntityOptions,
  doGetPayPlaceOptions,
  doGetContractTemplateOptions,
} from "@/api"

/** 创建[全局缓存选项]对象
 * @param {Object} p1
 * @param {String} p1.remark [全局缓存选项]备注信息 (用于console打印)
 * @param {Function} p1.doGetOptions 获取选项的接口
 * - 使用场景:选项基本不会变化，不重复请求接口
 */
function newHwOptionsCache({ remark, doGetOptions } = {}) {
  /** [全局缓存选项]选项值  */
  this.remark = remark
  /** [全局缓存选项]选项值  */
  this.value = []
  /** [全局缓存选项]有效 (目的:修改了选项配置后，全局选项失效) */
  this.isValid = false
  this._doGetOptions = doGetOptions

  /** [全局缓存选项]修改选项值 */
  this.setValue = function setValue(v) {
    // console.log("[全局缓存选项]修改选项值")
    if (Array.isArray(v)) {
      this.value = v
      this.isValid = true
    }
  }
  /** [全局缓存选项]设为无效 */
  this.setIsValidFalse = function () {
    // console.log("[全局缓存选项]设为无效")
    this.isValid = false
  }
}

/** 接口请求[获取选项] */
newHwOptionsCache.prototype.doGetOptions = async function () {
  let res = {
    options: [],
    isOk: false,
  }

  if (typeof this._doGetOptions !== "function") {
    showBug("请设置 doGetOptions")
    return res
  }

  try {
    res.options = await this._doGetOptions()
    res.isOk = true
    this.setValue(res.options) // 设置[全局缓存选项]
  } catch (e) {
    console.error(e)
  }
  console.log(`获取[全局缓存选项][${this.remark}]值`, res.options)
  return res
}

/** [签约主体主体][全局缓存选项] */
const HW_OPTIONS_CACHE_ENTITY = new newHwOptionsCache({
  remark: "签约主体主体",
  doGetOptions: doGetSignEntityOptions,
})
/** [险金缴纳地][全局缓存选项] */
const HW_OPTIONS_CACHE_PAY_PLACE = new newHwOptionsCache({
  remark: "险金缴纳地",
  doGetOptions: doGetPayPlaceOptions,
})
/** [合同模版][全局缓存选项] */
const HW_OPTIONS_CACHE_CONTRACT_TEMPLATE = new newHwOptionsCache({
  remark: "合同模版",
  doGetOptions: doGetContractTemplateOptions,
})

export {
  HW_OPTIONS_CACHE_ENTITY,
  HW_OPTIONS_CACHE_PAY_PLACE,
  HW_OPTIONS_CACHE_CONTRACT_TEMPLATE,
}
