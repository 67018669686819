/*
 * @Date: 2022-08-04 14:27:37
 * @LastEditTime: 2022-08-29 18:56:30
 */

/** 格式化10进制数字，保留几位小数
 * @param	{Object} p 参数
 * @param	{Number|String}	p.v	被处理数字
 * @param	{String} p.math	最后一位数字保留规则，即Math方法名称 (取值:[round:四舍五入,floor:向下,ceil:向上])
 * @param	{Number|String}	[p.place]	精确到小数点后几位 (默认:0)
 * @returns	{Number} 处理后的数字
 *
 * @descrition
 * 注意：`数字类型`没有尾数0，比如传入`1.2`，并保留2位，实际返回的是数字`1.2`，并不会返回字符串`1.20`，可手动toFixed(2)。
 *
 * @descrition
 * 处理函数抄自: [mdn](https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Math/round#%E5%B0%8F%E6%95%B0%E8%88%8D%E5%85%A5)
 *
 * @example
 * itv_formatDecimal({ v: 1.445, math: 'round', place: 2 }) // => 1.45 // [四舍五入]保留2位小数
 * itv_formatDecimal({ v: 1.445, math: 'floor', place: 1 }) // => 1.4 // [向下保留]保留1位小数
 * itv_formatDecimal({ v: 1.445, math: 'ceil', place: 1 }) // => 1.5 // [向上保留]保留1位小数
 */

function itv_formatDecimal(p = {}) {
  let value = p.v
  let type = p.math
  let exp = (p.place ?? 0) * -1 // (exp:指数=-`place`) (如:-2表示保留2位小数)

  // #逻辑 校验`math`
  const ITV_MATH_NAME_ARR = ["round", "floor", "ceil"]
  if (!ITV_MATH_NAME_ARR.includes(type)) {
    console.error(`[math]值无效，仅支持: ${ITV_MATH_NAME_ARR.join(", ")}`)
    return value
  }

  // 无需指数处理: exp=空/0
  if (exp === 0) {
    return Math[type](value)
  }
  value = +value
  // 异常处理，返回[NaN]: [value]非数字，[exp]非整数
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN
    // 知识点: typeof NaN === 'number' ，可以使用 toFixed
  }
  // 扩大[exp]*10倍，左移小数点，并按照[type]方法，去掉小数点后数字
  value = value.toString().split("e")
  value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
  // 缩小[exp]*10倍，右移小数点
  value = value.toString().split("e")
  return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
}

/** 金额[元]转[分] (整数) */
function itv_Yuan2Fen(v) {
  const _v = v * 100
  if (isNaN(_v)) return 0
  return parseInt(_v)
}

/** 金额[分]转[元] (小数)
 * @param {Number} v 金额分
 * @param {Number} [precision] 精度：保留小数点后几位 (默认:2)
 */
function itv_Fen2Yuan(v, precision = 2) {
  const _v = v * 1
  if (isNaN(_v)) return v
  let p = isNaN(precision) ? 2 : precision
  return ((v || 0) / 100).toFixed(p)
}

/** 数字保留0/2位
 * @description 目的: 简短显示数字
 */
function itv_toFixed02(v) {
  let _v = Number(v)
  if (isNaN(_v)) {
    return v
  }
  return _v.toFixed(Number.isInteger(_v) ? 0 : 2)
}

// 导出多个函数的的原因: 这些函数使用频率很高
export {
  itv_formatDecimal, // 格式化10进制数字，保留几位小数 (不常用)
  itv_Yuan2Fen, // 金额[元]转[分] (整数)
  itv_Fen2Yuan, // 金额[分]转[元] (小数)
  itv_toFixed02, // 数字保留0/2位
}
