/*
 * @Date: 2022-05-07 11:29:56
 * @LastEditTime: 2022-08-04 13:40:04
 */
// 显示消息组件的封装: 无需每次都引入 Notification, 更简单的调用。
import { Notification } from "element-ui"

function showNotice(title, message) {
  Notification.error({
    title,
    message,
    duration: 5000,
  })
}

function showNoticeMsg(title, message) {
  Notification.info({
    title,
    message,
    duration: 5000,
  })
}

export { showNotice, showNoticeMsg }
