<!--
 * @Date: 2022-08-15 14:54:06
 * @LastEditTime: 2022-08-15 15:12:29
-->
<!-- [ItvElPageTable][列表页面]顶部标题 ItvElPageTableH1 Itv-el-page-table-h1 -->
<template>
  <div class="Itv-el-page-table-h1">
    <slot name="prefix">
      <template v-if="parentName">
        <span class="cp" @click="$router.back()">{{ parentName }}</span>
        <span> / </span>
      </template>
    </slot>
    <span><slot name="default"></slot></span>
    <slot name="suffix"></slot>
  </div>
</template>

<script>
export default {
  name: "ItvElPageTableH1",
  components: {},
  mixins: [],
  props: {
    /** 标题归属的父路由 (点击可返回父路由) */
    parentName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
.Itv-el-page-table-h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
