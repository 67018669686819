/*
 * @Date: 2022-05-06 17:05:15
 * @LastEditTime: 2022-08-09 10:01:36
 */

import Vue from "vue"
import Vuex from "vuex"

import adminStore from "./admin"
import modalStore from "./modal"
import modalAddressStore from "./modalAddress"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // currentUser: {
    //   id: "",
    //   username: "",
    // },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    admin: adminStore,
    modal: modalStore,
    modalAddress: modalAddressStore,
  },
})
