/* 基于 el 上传 xlxs 文件(单个) ItvElUploadXlsx itv-el-upload-xlxs */
<template>
  <el-upload
    action=""
    :file-list="fileList"
    :multiple="false"
    :before-upload="onBeforeUpload"
    :show-file-list="false"
    :accpet="accpet"
    :disabled="_disabled"
    class="itv-el-upload-xlxs"
  >
    <template #trigger>
      <el-button type="primary" v-show="isShowUploadBtn" :disabled="_disabled">
        上传文件
      </el-button>
      <el-button
        type="primary"
        v-show="fileList.length && !showFileList"
        style="margin-left: 0"
        :disabled="_disabled"
      >
        重新上传
      </el-button>
    </template>
    <span class="fs14" :class="{ ml16: isShowUploadBtn }">
      <slot name="demoUrl">
        <a v-show="isShowUploadBtn" v-if="demoUrl" :href="demoUrl">
          下载模版
        </a>
      </slot>
    </span>
    <!-- 已上传的文件 -->
    <template v-if="fileList.length && showFileList">
      <div class="f-fs-c" v-for="(file, i) in fileList" :key="i">
        <!-- ?attname=${file.name}${
            file.name.includes('.xlsx') ? '' : '.xlsx'
          } -->
        <a class="mr8" :class="{ 'text-sub': _disabled }" :href="`${file.url}`">
          {{ file.name || "-" }}
        </a>
        <i
          v-show="!_disabled"
          class="el-icon-error cp"
          @click="onDelete(i)"
        ></i>
      </div>
    </template>
  </el-upload>
  <!-- </div> -->
</template>

<script>
import { itv_doGetQnToken, itv_doUploadQiniu } from "@/js/hw-js"
import mixinsItvElUpload from "@/components/mixins/mixinsItvElUpload" // emit:change list(watch) multiple maxCount fileList(watch) isShowUploadBtn onDelete disabled readonly _disabled
import { showError } from "@/js/itv-js-el"

export default {
  name: "ItvElUploadFile",
  components: {},
  mixins: [mixinsItvElUpload],
  props: {
    /** 模版下载地址 */
    demoUrl: {
      type: String,
      default: "",
    },
    /** 支持直接上传到 七牛云 (目的:外部手动调用组件的上传 doUploadFile) */
    autoQnUpload: {
      type: Boolean,
      default: true,
    },
    //
    /** 显示已上传的文件 */
    showFileList: {
      type: Boolean,
      default: true,
    },
    /** 七牛云存储文件夹前缀 (如:interval) */
    qnFolder: {
      type: String,
      required: true,
    },
    /** 七牛云文件地址前缀 (如:https://static.interval.im) */
    qnDomain: {
      type: String,
      required: true,
    },
    /** 文件类型 (表格:ITV_ACCPET_XLSX;pdf:application/pdf) */
    accpet: {
      type: String,
      required: true,
    },
  },
  // emit
  // onStart 导入开始
  // onFinish 导入成功/失败回调 返回 { err, url }
  data() {
    return {
      // accpet: ITV_ACCPET_XLSX,
    }
  },
  computed: {
    uploadUrl() {
      return this.fileList?.[0]?.url || "-"
    },
    // hasUpload() {
    //   return Boolean(this.uploadUrl)
    // },
  },
  created() {},
  mounted() {},
  methods: {
    /** 选择文件后的回调 (el上传文件之前的钩子)
     * @param {File} file 选择的文件
     * - 自定义上传(校验+上传): el 组件属性 before-upload 返回 false 或者返回 Promise 且被 reject，则停止上传。
     * - 默认: 选择文件后，自动[上传file到七牛云]
     * - 设置: autoQnUpload=false,可取消自动上传file到七牛云]，外部手动调用组件方法[doUploadFile]
     */
    onBeforeUpload(file) {
      if (!this.fileList[0]) {
        this.fileList.push({})
      }
      this.fileList[0].name = file.name
      this.fileList[0].originFileObj = file
      this.fileList[0].url = ""
      this.updateVModel(this.fileList, "input上传文件")

      // 直接[上传file到七牛云]
      if (this.autoQnUpload) {
        this.doUploadFile()
      }

      // 返回 false 则停止上传
      return false
    },

    /** 上传file到七牛云 */
    async doUploadFile() {
      const file = this.fileList[0].originFileObj
      if (!file) return showError("无文件")
      this.$emit("onStart")

      /** 上传后回调 */
      const onFinish = ({ err, url }) => {
        if (url) {
          this.fileList[0].url = url
          this.updateVModel(this.fileList, "上传文件到七牛云")
        }
        if (err) {
          showError(err)
        }
        this.$emit("onFinish", { err, url })
      }

      try {
        // 获取凭证
        const token = await itv_doGetQnToken()
        if (!token) {
          return onFinish({ err: "上传失败 缺少 token" }) // 导入成功/失败回调 返回 { err, url }
        }

        // console.log({ fileType: file.type })
        /** 校验文件类型 (后端暂时 只支持 .xlsx)
         * - .xls 对应 application/vnd.ms-excel
         * -.xlsx 对应 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
         */
        if (!this.accpet.includes(file.type)) {
          console.log(`当前表格类型: ${file.type}`, this.accpet)
          return onFinish({ err: "文件格式错误" })
        }

        const res = await itv_doUploadQiniu({
          token,
          file: file,
          qnFolder: this.qnFolder,
          domain: this.qnDomain,
          accpet: this.accpet,
        })
        if (res.err || !res.url) {
          return onFinish({ err: "上传失败101" })
        }
        return onFinish({ url: res.url })
      } catch (e) {
        console.error(e)
        return onFinish({ err: e })
      }
    },
  },
}
</script>

<style scoped lang="less">
.itv-el-upload-xlxs {
  display: flex;
  align-items: center;
}
</style>
