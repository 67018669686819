<!--
 * @Date: 2022-05-10 13:58:12
 * @LastEditTime: 2022-08-15 18:40:38
-->
/* (默认小号)按钮 ItvElBtnSmall itv-el-btn-small */
<template>
  <el-button
    class="hw-btn"
    :type="type"
    :size="size"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: "ItvElBtnSmall",
  components: {},
  mixins: [],
  props: {
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="less">
@import "@/styles/var.less";

//.itv-el-btn-small {
//}

.hw-btn.el-button--text:hover {
  opacity: 0.7;
}
/* 文字按钮兼容彩色 */
.hw-btn.el-button--text.text-title {
  &,
  &:hover,
  &:focus {
    color: @text-title;
  }
}
.hw-btn.el-button--text.text-sub {
  &,
  &:hover,
  &:focus {
    color: @text-sub2;
  }
}
.hw-btn.el-button--text.text-red {
  &,
  &:hover,
  &:focus {
    color: @error-color;
  }
}
.hw-btn.el-button--text.text-green {
  &,
  &:hover,
  &:focus {
    color: @success-color;
  }
}
</style>
