/*
 * @Date: 2022-05-18 10:10:11
 * @LastEditTime: 2022-08-26 18:25:00
 */
import * as qiniu from "qiniu-js"
import { getQnToken } from "@/api"
import { useItvQn, ITV_ACCPET_IMG } from "@/js/itv-js"

const {
  /** 获取七牛云上传token */
  itv_doGetQnToken,
  /** 上传文件到七牛云 */
  itv_doUploadQiniu,
} = useItvQn(qiniu, getQnToken)

/** 七牛云存储文件夹前缀 */
const HW_QINIU_DIR = "happy-work"
/** 七牛云文件地址前缀 */
const HW_QINIU_DOMIAN = "https://qn.ztcjob.cn"

/** 上传图片到七牛 (基于 element 的上传组件)
 * @param {Array} fileList .url:已上传,originFileObj:未上传
 * @param {Array} accept 支持的图片格式 默认 ITV_ACCPET_IMG
 * @param {String} qnFolder 上传的七牛云文件夹 默认 interval
 * @returns {String} err:上传错误
 */
const uploadImgsQiniu = async ({ fileList, accept, qnFolder }) => {
  let err = ""
  // 上传图片(数组)
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i]
    // 已上传
    if (file.url) {
      // console.log("图片已上传")
      continue
    }

    // 需上传
    // 1 获取七牛 token
    let token = await itv_doGetQnToken()
    if (!token) {
      err = "token获取失败"
      return err
    }
    // 2 上传
    const res = await itv_doUploadQiniu({
      token: token,
      file: file.originFileObj, // 注意,这里 基于 ant 的上传组件 返回的 文件数据
      qnFolder: qnFolder || HW_QINIU_DIR,
      domain: HW_QINIU_DOMIAN,
      accpet: accept || ITV_ACCPET_IMG,
    })
    // ant 的上传组件 数据状态
    if (res.url) {
      file.url = res.url
      // console.log("图片上传成功", res.url)
    } else if (res.err) {
      err = res.err
    }
  }
  return err
}

export {
  /** 获取七牛云上传token */
  itv_doGetQnToken,
  /** 上传文件到七牛云 */
  itv_doUploadQiniu,
  /* 其他 */
  HW_QINIU_DIR,
  HW_QINIU_DOMIAN,
  uploadImgsQiniu,
}
