<!--
 * @Date: 2022-05-11 20:07:49
 * @LastEditTime: 2022-07-26 13:41:14
-->
/* 查询输入框 ItvElInputSearch itv-el-input-search */
<template>
  <div class="itv-el-input-search f-fs-c">
    <el-input
      v-model="valueInner"
      :placeholder="placeholder"
      class="mr8 input-search"
      :clearable="true"
      @keyup.enter.native="onSearch"
      size="small"
    >
    </el-input>
    <el-button size="small" type="primary" @click="onSearch">查询</el-button>
  </div>
</template>

<script>
import { mixins_ItvElFormItem_value } from "@/components/mixins/mixinsItvElInput" // placeholder value(:watch) valueInner

export default {
  name: "ItvElInputSearch",
  components: {},
  mixins: [mixins_ItvElFormItem_value],
  props: {
    // 占位符
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onSearch() {
      this.$emit("input", this.valueInner) // 更新 输入框值 (仅提交时更新,不提交没有必要呢)
      this.$emit("onSearch") // 触发 查询
    },
  },
}
</script>

<style scoped lang="less">
.itv-el-input-search {
  .input-search {
    width: 240px;
  }
}
</style>
