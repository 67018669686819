/* 分页器 (好像没有封装什么...) ItvElPagination itv-el-pagination */
<template>
  <div class="f-c-c mt8 itv-el-pagination">
    <el-pagination
      @current-change="onPageCurrent"
      :current-page="innerPageCurrent"
      :page-size="innerPageSize"
      :total="innertotal"
      layout="total, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "ItvElPagination",
  components: {},
  mixins: [],
  // https://cn.vuejs.org/v2/api/#model
  props: {
    pagination: {
      type: Object,
      default: null,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    // pageCurrent: {
    //   type: Number,
    //   default: 1,
    // },
  },
  data() {
    return {
      innerPageSize: 10,
      innerPageCurrent: 1, // 当前第几页 (!不会更新外部传入的 pagination.page , 需要在回调 onPageCurrent 中手动更新 pagination.page)
      innertotal: 0,
    }
  },
  computed: {},
  watch: {
    pagination: {
      handler(v) {
        if (v) {
          // 这里是 itv 风格的数据
          this.innerPageSize = v.per_page || this.pageSize
          this.innerPageCurrent = v.page || 1
          this.innertotal = v.total || this.total || 0
          // console.log(`[pagination 监听]`, {
          //   innerPageSize: this.innerPageSize,
          //   innerPageCurrent: this.innerPageCurrent,
          //   innertotal: this.innertotal,
          // })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    onPageCurrent(v) {
      console.log("inner onPageCurrent", v)
      this.$emit("onPageCurrent", { page: v })
    },
  },
}
</script>

<style scoped lang="less">
//.itv-el-pagination {
//}
</style>
