/*
 * @Date: 2022-08-16 11:45:29
 * @LastEditTime: 2022-08-16 12:07:09
 */

/* 复用 动态 组件 描述类型 需要配置的数据

饿了吗描述类型 https://element.eleme.io/#/zh-CN/component/descriptions

使用方式:
import {
  mixinsItvElDescriptionCtn, // config data dataIndex value
} from "@/components/ItvElDescription/mixinsItvElDescriptionCtn.js"

  mixins: [mixinsItvElDescriptionCtn],

 */

/** [ItvElDescription.vue]的[动态的]内容子组件，复用的js逻辑 */
export const mixinsItvElDescriptionCtn = {
  props: {
    /** 动态组件[配置参数] (因为是动态组件，使用一个参数，保证参数的统一) */
    config: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    /** 全部[数据] */
    data() {
      return this.config?.data || {}
    },
    /** 对应[数据]键名 */
    dataIndex() {
      return this.config?.dataIndex || ""
    },
    /** 对应[数据]值 */
    value() {
      return this.data[this.dataIndex]
    },
  },
}
