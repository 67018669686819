/*
 * @Date: 2022-06-14 10:32:48
 * @LastEditTime: 2022-09-05 12:31:36
 */

// 使用示例
// // utils/token.js
// import { useItvToken } from "itv-js.js";
// import jsCookie from "js-cookie";
// const TOKEN_KEY = process.env.VUE_APP_TOKEN; // token 存放的键名
// const itvToken = useItvToken(XMC_TOKEN, jsCookie);
// /** 获取cookie中的[token] */
// const itv_getCacheToken = itvToken.itv_getCacheToken;
// /** 设置cookie中[token] */
// const itv_setCacheToken = itvToken.itv_setCacheToken;

/** [依赖项目npm:js-cookie][根据项目]创建返回itv常用[用户token处理]逻辑模块
 * @description (为了 vuex + request.js 中同时使用)
 * @param {String} tokenKey 存在cookie中的token键名
 * @param {Object} jsCookie 库 [js-cookie](https://www.npmjs.com/package/js-cookie) (目的: itv-js-lib 不依赖库)
 * @returns {Object} 常用[用户token处理]逻辑
 *
 * @description 封装
 *
 * #逻辑1 itv_getCacheToken: 获取cookie中的[token]
 *
 * #逻辑2 itv_setCacheToken: 设置cookie中[token]
 *
 * #逻辑21 设置cookie[token]有效期为30天
 *
 * 注意: 30天没有执行[itv_setCacheToken]，
 * 即使使用[itv_getCacheToken]实现自动登录/保持登录状态，30天后需要重新登录。
 * (感觉应该自动延长登录时效...)
 *
 * #逻辑22 传入值为空，则remove清楚cookie中[token]，即退出登录
 */
function useItvToken(tokenKey, jsCookie) {
  /** 获取cookie中的[token] #逻辑1  */
  function itv_getCacheToken() {
    let token = ""
    try {
      token = jsCookie.get(tokenKey) || ""
    } catch (e) {
      console.error(e)
    }
    return token
  }

  /** 设置cookie中[token] #逻辑2 */
  function itv_setCacheToken(token) {
    try {
      // 清除 // #逻辑22
      if (!token) {
        return jsCookie.remove(tokenKey)
      }
      // 设置 (有效期30天) // #逻辑21
      jsCookie.set(tokenKey, token, { expires: 30 })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    /** 获取cookie中的[token] */
    itv_getCacheToken,
    /** 设置cookie中[token] */
    itv_setCacheToken,
  }
}

export {
  useItvToken, // [依赖项目npm:js-cookie][根据项目]生成用户[token]的处理逻辑模块
}
