/*
 * @Date: 2022-05-06 17:05:15
 * @LastEditTime: 2022-08-16 11:30:26
 */

import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "@/store"

import ElementUI from "element-ui"
// import ItvButtonTest from "@/test/ItvButtonTest.vue"

// 自定义公共组件
import "@/components/index"

// [全部样式]
import "./styles/index.less"

Vue.use(ElementUI)
// Vue.component("ItvButtonTest", ItvButtonTest)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
