<!--
 * @Date: 2022-08-10 11:49:59
 * @LastEditTime: 2022-08-17 17:57:32
-->
<!-- 信息图标-旗袍提示 ItvElTip itv-el-tip
实现：
- 封装 icon 信息图标
 -->
<template>
  <!-- 气泡提示 https://element.eleme.io/#/zh-CN/component/tooltip -->
  <el-tooltip
    v-bind="$attrs"
    :placement="placement"
    effect="dark"
    class="itv-el-tip"
  >
    <div slot="content">
      <slot name="content">
        {{ content }}
      </slot>
    </div>
    <i class="el-icon-info text-sub"></i>
  </el-tooltip>
  <!-- 弹出框 https://element.eleme.io/#/zh-CN/component/popover -->
  <!-- <el-popover v-bind="$attrs" trigger="hover" class="itv-el-tip">
    <i class="el-icon-info text-sub" slot="reference"></i>
  </el-popover> -->
</template>

<script>
export default {
  name: "ItvElTip",
  components: {},
  mixins: [],
  props: {
    content: {
      default: "",
    },
    placement: {
      validator(v) {
        return v
          ? [
              "top",
              "top-start",
              "top-end",
              "bottom",
              "bottom-start",
              "bottom-end",
              "left",
              "left-start",
              "left-end",
              "right",
              "right-start",
              "right-end",
            ].includes(v)
          : true
      },
      default: "top",
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.itv-el-tip {
//}
</style>
