<!--
 * @Date: 2022-07-30 13:29:08
 * @LastEditTime: 2022-08-16 14:20:08
-->
/* form表单底部的按钮 ItvElFormFooter itv-el-form-footer */
<template>
  <div class="itv-el-form-footer f-fe-c">
    <el-button @click="onCancle" v-if="isShowCancle">
      {{ cancleText }}
    </el-button>
    <el-button :disabled="sureDisabled" type="primary" @click="onSure">{{
      sureText
    }}</el-button>
  </div>
</template>

<script>
export default {
  name: "ItvElFormFooter",
  components: {},
  mixins: [],
  props: {
    cancleText: {
      type: String,
      default: "取消",
    },
    sureText: {
      type: String,
      default: "确认",
    },
    // 禁用 确认按钮
    sureDisabled: {
      type: Boolean,
      default: false,
    },
    /** 显示[取消]按钮 */
    isShowCancle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onCancle() {
      this.$emit("onCancle")
    },
    onSure() {
      this.$emit("onSure")
    },
  },
}
</script>

<style scoped lang="less">
//.itv-el-form-footer {
//}
</style>
