/*
 * @Date: 2022-07-29 11:41:12
 * @LastEditTime: 2022-08-29 17:02:20
 */
import { itv_validateByReg } from "./itv-validate"

// itv-validate-rule-required.js 应用场景: 表单项很多，且需要校验

/** [rule]必填规则支持的[类型][type]
 *
 * @description `ITV_RULE_KEY_MAP` 目的: 更直观地管理维护支持规则类型。
 * 其键值对象的数据格式，如下：
 * {String}   message         必填提示
 * {String}   type            变量类型
 * {Function} getValidator   `validator`生成器
 *
 * @description (#逻辑2)
 * string:    字符串类型         (常规类型)
 * boolean:   布尔类型
 * date:      日期类型
 * daterange: 数组/日期范围      (前端组件)
 * file:      数组/文件
 * img:       数组/图片
 * phone:     字符串类型/手机号   (业务逻辑)
 */
const ITV_RULE_KEY_MAP = {
  string: {
    message: "请输入",
    type: "string",
  },
  boolean: {
    message: "请选择",
    type: "boolean",
  },
  date: {
    message: "请选择",
    type: "date",
  },
  array: {
    message: "请选择",
    type: "array",
  },
  daterange: {
    type: "array",
    getValidator: (message) => {
      return (rule, value, callback) => {
        let err = ""
        if (!value || value?.length !== 2) {
          err = message || "请选择"
        }
        err ? callback(new Error(err)) : callback()
      }
    },
  },
  file: {
    type: "array",
    getValidator: (message) => {
      return (rule, value, callback) => {
        let err = ""
        if (!value || !value[0]) {
          err = message || "请上传文件"
        }
        err ? callback(new Error(err)) : callback()
      }
    },
  },
  img: {
    type: "array",
    getValidator: (message) => {
      return ITV_RULE_KEY_MAP["file"].getValidator(message || "请上传图片")
    },
  },
  phone: {
    type: "string",
    getValidator: (message) => {
      return (rule, value, callback) => {
        let err = itv_validateByReg({
          value,
          regKey: "phone",
          requiredMsg: message,
        })
        err ? callback(new Error(err)) : callback()
      }
    },
  },
}

/** [rule]必填规则支持的[类型][type]
 * @description 用于校验`type`
 */
const ITV_RULE_KEY_ARR = Object.keys(ITV_RULE_KEY_MAP)

/** 获取组件的[rule]必填规则
 * @param {Object|String} [p] 参数 (支持传入字符串，表示[p.message])
 * @param {String} [p.message] 未输入时，提示文案 (默认:请输入/请选择)
 * @param {String} [p.ruleKey] `前端组件`或`业务逻辑`的数据类型 (取值:`ITV_RULE_KEY_MAP`的键值) (默认:string)
 * @param {String} [p.trigger] 触发校验的方式 (默认:blur)
 * @returns {Object} [rule]校验对象
 *
 * @description 校验规则更多查看[async-validator](https://github.com/yiminghe/async-validator)
 *
 * @description 目的: 兼容`前端组件`或`业务逻辑`的数据类型的必填校验
 *
 * @description 封装
 *
 * #逻辑1 快速返回必填规则[rule]对象 {required:true,trigger:触发校验的方式,type:数据类型[,message:必填提示,validator:自定义校验函数]}
 *
 * #逻辑1_1 封装`前端组件`或`业务逻辑`常用的`validator`自定义校验。
 * 注意: 不支持设置`validator`参数。
 *
 * #逻辑1_2 若设置了`validator`，则不设置`message`。
 * 原因: 如果设置`rule`的`message`字段，那么`validator`中的 new Error("message无效") 将失效，即`rule.message`的优先级最高。
 *
 * #逻辑2 扩展`async-validator`的`type`，增加`前端组件`或`业务逻辑`的数据类型 (如: 日期范围、手机号)
 *
 * #逻辑2_1 校验传入`ruleKey`值是否有效
 *
 */
function itv_get_ruleRequired(p = {}) {
  if (typeof p === "string") {
    p = { message: p }
  }

  let ruleKey = p.ruleKey || "string"
  if (!ITV_RULE_KEY_ARR.includes(ruleKey)) {
    ruleKey = "string"
    console.error(`[type]值无效，仅支持: [${ITV_RULE_KEY_ARR.join(", ")}]`)
  }

  const p_default = ITV_RULE_KEY_MAP[ruleKey]
  const type = p.ruleKey || p_default.type
  const message = p.message || p_default.message
  const getValidator = p_default.getValidator

  // #逻辑1
  let rule = {
    required: true,
    trigger: p.trigger || "blur",
    type,
  }

  if (getValidator) {
    // #逻辑1_1
    rule.validator = getValidator(message)
  } else if (message) {
    // #逻辑1_2
    rule.message = message
  }

  return rule
}

export {
  itv_get_ruleRequired, // 获取组件的[rule]必填规则
}
