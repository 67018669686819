/*
 * @Date: 2022-07-27 18:31:36
 * @LastEditTime: 2022-08-26 16:10:01
 */

import {
  itv_get_optionItem, // 将[对象]转化为[选项组件]需要的数据结构 {value, label}
  itv_get_optionValueMap, // 根据[optionArr]获取以选项[value]作为键值的对象[map]
  itv_get_options, // 根据[optionArr]获取[选项组件]所需的[options]数组
} from "@/js/itv-js"

/** 需要/不需要 (!不能修改 value) */
const HW_OPTIONS_BOOLEAN_NEED = [
  { label: "需要", value: true },
  { label: "不需要", value: false },
]
/** 显示/不显示 (!不能修改 value) */
const HW_OPTIONS_BOOLEAN_SHOW = [
  { label: "显示", value: true },
  { label: "不显示", value: false },
]
/** 开/关 (!不能修改 value) */
const HW_OPTIONS_BOOLEAN_OPEN = [
  { label: "开", value: true },
  { label: "关", value: false },
]
/** 是/否 (!不能修改 value) */
const HW_OPTIONS_BOOLEAN_YES = [
  { label: "是", value: true },
  { label: "否", value: false },
]

/** 不限/是/否[是] */
const HW_OPTION_STIRNG_YES = 1
/** 不限/是/否 (!不能修改 value) */
const HW_OPTIONS_STIRNG_YES = [
  { label: "不限", value: "" },
  { label: "否", value: 0 },
  { label: "是", value: HW_OPTION_STIRNG_YES },
]

/** 发薪日/批扣日(1-28) */
const HW_OPTIONS_DATE = new Array(28).fill("").map((item, index) => {
  let v = String(index + 1)
  return { label: v, value: v }
})

export {
  /** 获取[选项组件]需要的数据结构 {value, label} */
  itv_get_optionItem,
  /** 根据[全部选项配置数组]获取[以选项value作为键值的地图map(文案map)] */
  itv_get_optionValueMap,
  /** 根据[全部选项配置数组]获取[选项组件]所需的[组件选项数组] */
  itv_get_options,
  // 复用的表单选项
  HW_OPTIONS_BOOLEAN_NEED,
  HW_OPTIONS_BOOLEAN_SHOW,
  HW_OPTIONS_BOOLEAN_OPEN,
  HW_OPTIONS_STIRNG_YES,
  HW_OPTION_STIRNG_YES,
  HW_OPTIONS_BOOLEAN_YES,
  HW_OPTIONS_DATE,
}
