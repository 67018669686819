// 目的: 对话框[HwDialogAddress.vue]全局单一实例, 数据全局管理
import { showError } from "@/js/itv-js-el"

const modalConfigDeault = {
  isShow: false, // 对话框 是否显示
  title: "", // 对话框 标题
  width: "800px", // 对话框 宽度
  dynamicComponentConfig: {},
}

const modalAddressStore = {
  state() {
    return {
      // 对话框 配置
      dialogConfig: {
        ...modalConfigDeault,
      },
      /** 打开对话框的时间戳
       * (目的:使用 useVuexModalAddress.js 需监听[valueHwPickerAddress]的变化，
       * 再回调[setFromValueHwPickerAddress]修改页面中[.vue中地址输入框]的内容值)
       */
      openModalTimestamp: "",

      /** HwPickerAddress.vue 组件对应选中值
       * (目的: 让 .vue 文件监听当前选中的地址信息)
       */
      valueHwPickerAddress: {
        // detailAddress: "", // 展示地址
        // adcode: "", // 区域编码
        // citycode: "", // 长途区号 城市编码
        // province: "", // 省份
        // city: "", // 市
        // district: "", // 区
        // township: "", // 街道
        // street: "", // 路
        // lng: "", // 经度
        // lat: "", // 纬度
      }, // 对话框点击 提交 返回的 form

      /** 已经渲染 HwDialogAddress.vue (目的: 懒加载) */
      hasRenderModal: false,
    }
  },
  getters: {
    isShowModalAddress: (state) => {
      return Boolean(state.dialogConfig.isShow)
    },
  },
  mutations: {
    /** (目的: 懒得写太多的 mutations) */
    setState(state, { stateName, stateValue } = {}) {
      if (stateName) {
        state[stateName] = stateValue
      }
    },

    /** (目的: 显示对话框) */
    setModalAddress(state, v) {
      for (let key in state.dialogConfig) {
        state.dialogConfig[key] = v[key] ?? modalConfigDeault[key]
      }
    },

    /** (目的: 隐藏对话框) */
    setModalAddressHide(state) {
      state.dialogConfig.isShow = false
    },
  },
  actions: {
    /** 显示对话框 */
    showModalAddress(ctx, v) {
      let dialogConfig = {
        title: v?.title,
        dynamicComponentConfig: v?.dynamicComponentConfig || {},
        isShow: true,
      }
      // console.log({ dialogConfig })
      ctx.commit("setModalAddress", dialogConfig)
      if (!ctx.hasRenderModal) {
        ctx.commit("setState", {
          stateName: "hasRenderModal",
          stateValue: true,
        })
      }
    },

    /** 隐藏对话框 */
    closeModalAddress(ctx) {
      ctx.commit("setModalAddressHide")
    },

    /** 点击[.vue中地址输入框]=显示弹窗[HwDialogAddress]，对话框标题:选择地址 */
    onSelectAddress($store, v) {
      if (!v.openModalTimestamp) {
        return showError("openModalTimestamp 未传入")
      }
      // 同步.vue时间戳 (目的: 确保.vue中数据监听正确)
      $store.commit("setState", {
        stateName: "openModalTimestamp",
        stateValue: v.openModalTimestamp,
      })
      // 显示弹窗
      $store.dispatch("showModalAddress", {
        title: "选择地址",
        dynamicComponentConfig: {
          initData: v.initData,
          cbOnSubmit: (res) => {
            console.log("🚀 1", { res })

            // 点击确认回调
            // 目的: 让 .vue 监听获取到 HwPickerAddress.vue 中确认数据
            $store.commit("setState", {
              stateName: "valueHwPickerAddress",
              stateValue: res,
            })
          },
        },
      })
    },

    /** 显示弹窗[HwDialogAddress]，对话框标题:地址信息 (未使用) */
    // onShowAddress(ctx, v) {
    //   ctx.dispatch("showModalAddress", {
    //     title: "地址信息",
    //     dynamicComponentConfig: {
    //       initData: v.initData,
    //       readonly: true,
    //     },
    //   })
    // },
  },
  modules: {},
}

export default modalAddressStore
