/*
 * @Date: 2022-07-29 11:25:47
 * @LastEditTime: 2022-10-08 11:48:29
 */

/** 获取[ref引用指向的对象]
 * @param {String} refName [ref引用指向的对象]ref绑定的值
 * @returns {Object} [ref引用指向的对象]对象
 */
function itv_get_refTarget(refName) {
  let refTarget = null
  // #逻辑 1 [校验][必要参数]是否传入`this` (原因: `refTarget`需通过`this.$refs`获取)
  if (!this) {
    console.error(`未传入this，传入方式: 方法名称.call(this, ${refName})`)
    return refTarget
  }

  // #逻辑 2 [校验][必要参数]是否传入[refName]
  if (!refName) {
    console.error("请传入[refName]")
    return refTarget
  }

  refTarget = this.$refs[refName]

  // #逻辑 3 [校验][ref引用指向的对象]是否渲染
  if (!refTarget) {
    console.error(`ref:[引用指向的对象] ${refName} 未渲染`)
    return refTarget
  }

  // #逻辑 4 返回[ref引用指向的对象]：`refTarget`
  return refTarget
}

/** 执行[ref引用指向的对象][内部方法](不支持异步)，并返回[执行结果]
 * @param {Object} p1
 * @param {String} p1.refName [ref引用指向的对象]ref绑定的值
 * @param {String} p1.methodName [内部方法]名称
 * @param {*} p1.[methodParams] [内部方法]参数
 * @returns {*} [内部方法][执行结果]
 * - 应用场景: 自己开发的组件，可能方法不存在...
 */
function itv_execute_refMethod({ refName, methodName, methodParams } = {}) {
  let logicText = `执行[${refName}]内部方法`
  /** [内部方法][执行结果] */
  let res = null

  function dealRes(error) {
    if (error) {
      console.error(`${logicText} 失败: ${error}`)
    }
    return res
  }

  const _ref = itv_get_refTarget.call(this, refName)
  if (!_ref) {
    return dealRes(`获取[ref=${refName}]绑定的实例，失败`)
  }
  if (typeof _ref[methodName] !== "function") {
    return dealRes(`[${refName}.${methodName}]不是函数`)
  }

  res = _ref[methodName](methodParams)
  return dealRes()
}

export {
  itv_get_refTarget, // 获取[ref引用指向的对象]
  itv_execute_refMethod, // 执行[ref引用指向的对象][内部方法]，并返回[执行结果]
}
