/*
 * @Date: 2022-07-28 09:22:33
 * @LastEditTime: 2022-08-15 12:16:52
 */

/** 项目全部的接口:
 * - 注意: [api]文件夹，放置的是`基础公共函数`，所以不要引用别的`基础公共函数`的入口文件(index.js)，避免循环引用。 (2022/08/15)
 * - 输入: `itvrequest`可快速创建 request 函数
 */
//
import { request } from "@/js/hw-js/hw-request"
import { itv_get_optionItem } from "@/js/hw-js/hw-options"

/** 修改密码 */
const putAdminPwd = (params) => {
  return request({
    url: "/api/password/",
    method: "put",
    params,
  })
}

/** 登录 */
const putLogin = (params) => {
  return request({
    url: "/api/admin/login/",
    method: "put",
    params,
  })
}

/** 获取当前管理员详情 */
const getAdminInfo = () => {
  return request({
    url: "/api/current_admin/",
    method: "get",
  })
}

/** 获取七牛上传凭证 */
const getQnToken = () => {
  return request({
    url: "/api/qn/upload_token/",
    method: "get",
  })
}

/** 列出公司 */
const getCompanyList = (params) => {
  return request({
    url: "/api/company/",
    method: "get",
    params,
  })
}

/** 创建公司 */
const postCompany = (params) => {
  return request({
    url: "/api/company/",
    method: "post",
    params,
  })
}

/** 更新公司 */
const putCompany = (id, params) => {
  return request({
    url: `/api/company/${id}/`,
    method: "put",
    params,
  })
}

/** 列出公司的子公司 */
const getStoreList = (id, params) => {
  return request({
    url: `/api/company/${id}/sub/`,
    method: "get",
    params,
  })
}

/** 创建子公司/门店 */
const postStore = (id, params) => {
  return request({
    url: `/api/company/${id}/sub/`,
    method: "post",
    params,
  })
}

/** 更新子公司 */
const putStore = (cid, sid, params) => {
  return request({
    url: `/api/company/${cid}/sub/${sid}/`,
    method: "put",
    params,
  })
}

/** 删除子公司 */
const deleteStore = (cid, sid, params) => {
  return request({
    url: `/api/company/${cid}/sub/${sid}/`,
    method: "delete",
    params,
  })
}

/** 公司注册上上签账号 */
const putSsq = (cid) => {
  return request({
    url: `/api/company/${cid}/ssq/`,
    method: "put",
    params: {},
  })
}

/** 获取[公司]选项 (复用) */
async function doGetCompanyList(keyword) {
  let companies = []
  try {
    const res = await getCompanyList({
      page: 1,
      per_page: 100,
      keyword,
    })
    companies = res.companies || []
  } catch (e) {
    console.error(e)
  }
  return companies
}
/** 获取[公司]选项 */
const doGetCompanyOptions = async (keyword) => {
  let options = []
  try {
    let companies = await doGetCompanyList(keyword)
    options = companies.map(itv_get_optionItem)
  } catch (e) {
    console.error(e)
  }
  return options
}
/** 获取[公司]及[门店/子公司]选项 */
const doGetCompanyStoreOptions = async (keyword) => {
  let optionsCompany = []
  let optionsStoreMap = {}
  try {
    let companies = await doGetCompanyList(keyword)
    optionsCompany = companies.map((item) => {
      let option = itv_get_optionItem(item)
      let stores = item.subs || []
      stores = stores.map(itv_get_optionItem)
      optionsStoreMap[item.sid] = stores
      return option
    })
  } catch (e) {
    console.error(e)
  }
  return { optionsCompany, optionsStoreMap }
}

/** 获取[门店/子公司]选项 */
const doGetStoreOptions = async (id) => {
  let options = []
  try {
    let res = await getStoreList(id)
    options = res.subs || []
    options = options.map((item) => ({
      value: item.sid,
      label: item.name,
    }))
  } catch (e) {
    console.error(e)
  }
  return options
}

/** 列出职位 */
const getJobList = (params) => {
  return request({
    url: "/api/job/",
    method: "get",
    params,
  })
}

/** 列出子公司职位 */
const getJobListByStore = (params) => {
  return request({
    url: "/api/job_sub/",
    method: "get",
    params,
  })
}

/** 创建母职业 */
const postParentJob = (params) => {
  return request({
    url: "/api/job/",
    method: "post",
    params,
  })
}

/** 更新职位 (母) */
const putParentJob = (id, params) => {
  return request({
    url: `/api/job/${id}/`,
    method: "put",
    params,
  })
}

/** 创建子职业 */
const postChildJob = (id, params) => {
  return request({
    url: `/api/job/${id}/sub/`,
    method: "post",
    params,
  })
}

/** 更新职位状态 (子) */
const putJobEnable = (id, params) => {
  return request({
    url: `/api/job/${id}/enable/`,
    method: "put",
    params,
  })
}

/** 获取[门店/子公司]职位 */
const doGetStoreJobOptions = async ({ storeId } = {}) => {
  let options = []
  if (!storeId) {
    console.error("storeId 404")
    return options
  }
  try {
    let res = await getJobListByStore({
      page: 1,
      per_page: 100,
      company_sub: storeId,
      enabled: 1, // 是否启用（1、true为启用，0、false为禁用）
    })
    options = res.jobs || []
    options = options.map(itv_get_optionItem)
    console.log("[doGetStoreJobOptions]", options)
  } catch (e) {
    console.error(e)
  }
  return options
}

/** 列出签约主体 */
const getSignEntityList = (params) => {
  return request({
    url: "/api/entity/",
    method: "get",
    params,
  })
}

/** 创建签约主体 */
const postSignEntity = (params) => {
  return request({
    url: "/api/entity/",
    method: "post",
    params,
  })
}

/** 更新签约主体 */
const putSignEntity = (id, params) => {
  return request({
    url: `/api/entity/${id}/`,
    method: "put",
    params,
  })
}

/** 获取签约主体的支付信息 */
const getSignEntityPayInfo = (id, params) => {
  return request({
    url: `/api/entity/${id}/pay_info/`,
    method: "get",
    params,
  })
}

/** 更新签约主体的支付信息 */
const putSignEntityPayInfo = (id, params) => {
  return request({
    url: `/api/entity/${id}/pay_info/`,
    method: "put",
    params,
  })
}

/** 获取[签约主体]全部选项 */
const doGetSignEntityOptions = async () => {
  let options = []
  try {
    let res = await getSignEntityList({
      page: 1,
      per_page: 100,
    })
    options = (res.entities || []).map(itv_get_optionItem)
  } catch (e) {
    console.error(e)
  }
  return options
}
/** 列出险金缴纳地 */
const getPayPlaceList = (params) => {
  return request({
    url: `/api/ins_location/`,
    method: "get",
    params,
  })
}

/** 查询某地某年月缴纳配置 */
const getPayPlaceConfig = (id, params) => {
  return request({
    url: `/api/ins_location/${id}/config/`,
    method: "get",
    params,
  })
}

/** 创建险金缴纳地 */
const postPayPlace = (params) => {
  return request({
    url: `/api/ins_location/`,
    method: "post",
    params,
  })
}

/** 创建缴纳配置 */
const postPayPlaceConfig = (id, params) => {
  return request({
    url: `/api/ins_location/${id}/config/`, // 缴纳地sid
    method: "post",
    params,
  })
}

/** 更新缴纳配置 */
const putPayPlaceConfig = (id, params) => {
  return request({
    url: `/api/ins_cfg/${id}/`, // 缴纳配置的sid
    method: "put",
    params,
  })
}

/** 删除缴纳配置 */
const deletePayPlaceConfig = (id, params) => {
  return request({
    url: `/api/ins_cfg/${id}/`, // 缴纳配置的sid
    method: "delete",
    params,
  })
}

/** 创建险金批扣 */
const postInsDeduct = (id, params) => {
  return request({
    url: `/api/user/${id}/ins_record/`,
    method: "post",
    params,
  })
}

/** 列出险金批扣记录 */
const getInsDeductList = (params) => {
  return request({
    url: `/api/ins_record/`,
    method: "get",
    params,
  })
}

/** [缴纳地]全部可用选项 */
const doGetPayPlaceOptions = async (keyword) => {
  let options = []
  try {
    let res = await getPayPlaceList({
      page: 1,
      per_page: 100,
      keyword,
    })
    options = res.locations || []
    // 去掉\选项\没有缴纳配置
    options = options.filter((option) => option?.configs?.length > 0)
    options = options.map(itv_get_optionItem)
  } catch (e) {
    console.error(e)
  }
  return options
}

/** 列出缴纳用户 */
const getUserListPayIns = (params) => {
  return request({
    url: `/api/user_ins/`,
    method: "get",
    params,
  })
}

/** 导出缴纳用户 */
const getUserListPayInsExport = (params) => {
  return request({
    url: `/api/user_ins/export/`,
    method: "get",
    params,
  })
}

/** 导出险金批扣记录 */
const getInsDeductListExport = (params) => {
  return request({
    url: `/api/ins_record/export/`,
    method: "get",
    params,
  })
}

/** 列出合同模板 */
const getContractTemplateList = (params) => {
  return request({
    url: `/api/contract_tmpl/`,
    method: "get",
    params,
  })
}

/** 创建合同模板 */
const postContractTemplate = (params) => {
  return request({
    url: `/api/contract_tmpl/`,
    method: "post",
    params,
  })
}

/** 更新合同模板 */
const putContractTemplate = (id, params) => {
  return request({
    url: `/api/contract_tmpl/${id}/`,
    method: "put",
    params,
  })
}

/** 列出合同 */
const getContractList = (params) => {
  return request({
    url: "/api/contract/",
    method: "get",
    params,
  })
}

/** 创建合同 */
const postContract = (params) => {
  return request({
    url: "/api/contract/",
    method: "post",
    params,
  })
}

/** 更新合同 */
const putContract = (id, params) => {
  return request({
    url: `/api/contract/${id}/`,
    method: "put",
    params,
  })
}

/** 合同确认签署 (审核签署) */
const putConfirmContract = (id, params) => {
  return request({
    url: `/api/contract/${id}/confirm/`,
    method: "put",
    params,
  })
}

/** 合同驳回 (驳回重签) */
const putRejectContract = (id, params) => {
  return request({
    url: `/api/contract/${id}/reject/`,
    method: "put",
    params,
  })
}

/** 合同放弃签署 (放弃入职) */
const putGiveUpContract = (id, params) => {
  return request({
    url: `/api/contract/${id}/release/`,
    method: "put",
    params,
  })
}

/** 用户离职 */
const putLeaveContract = (id, params) => {
  return request({
    url: `/api/contract/${id}/leave/`,
    method: "put",
    params,
  })
}

/** 获取[合同模板]全部选项 */
const doGetContractTemplateOptions = async () => {
  let options = []
  try {
    let res = await getContractTemplateList({
      page: 1,
      per_page: 100,
    })
    options = (res.templates || []).map(itv_get_optionItem)
  } catch (e) {
    console.error(e)
  }
  return options
}

/** 列出离职申请 */
const getResignApplicationList = (params) => {
  return request({
    url: `/api/resign_apply/`,
    method: "get",
    params,
  })
}

/** 取消离职申请 */
const putRejectResignApplication = (id, params) => {
  return request({
    url: `/api/resign_apply/${id}/cancel/`,
    method: "put",
    params,
  })
}

/** 确认离职申请 */
const putSureResignApplication = (id, params) => {
  return request({
    url: `/api/resign_apply/${id}/confirm/`,
    method: "put",
    params,
  })
}

/** 更新用户合同工资 */
const putContractSalary = (id, params) => {
  return request({
    url: `/api/contract/${id}/salary/`,
    method: "put",
    params,
  })
}

/** 列出用户 */
const getUserList = (params) => {
  return request({
    url: "/api/user/",
    method: "get",
    params,
  })
}

/** 获取用户详情 */
const getUserDetail = (id, params) => {
  return request({
    url: `/api/user/${id}/`,
    method: "get",
    params,
  })
}

/** 更新用户身份信息 */
const putUserId = (id, params) => {
  return request({
    url: `/api/user/${id}/id/`,
    method: "put",
    params,
  })
}

/** 更新用户教育信息 */
const putUserEdu = (id, params) => {
  return request({
    url: `/api/user/${id}/edu/`,
    method: "put",
    params,
  })
}

/** 更新用户银行卡信息 */
const putUserBank = (id, params) => {
  return request({
    url: `/api/user/${id}/account/`,
    method: "put",
    params,
  })
}

/** 更新用户居住地址 */
const putUserAddress = (id, params) => {
  return request({
    url: `/api/user/${id}/address/`,
    method: "put",
    params,
  })
}

/** 列出打卡用户（任务挑战用户） */
const getUserListCheck = (params) => {
  return request({
    url: `/api/user_check/`,
    method: "get",
    params,
  })
}

/** 创建或更新用户的社保缴纳信息 */
const putUserInsPay = (id, params) => {
  return request({
    url: `/api/user/${id}/ins/`,
    method: "put",
    params,
  })
}

/** 更新用户打卡等级 */
const putUserCheckLevel = (id, params) => {
  return request({
    url: `/api/user/${id}/check_level/`,
    method: "put",
    params,
  })
}

/** 列出用户打卡记录 */
const getCheckList = (id, params) => {
  return request({
    url: `/api/user/${id}/check_records/`,
    method: "get",
    params,
  })
}
/** 列出用户打卡记录 */
const getUserSignPdfList = (id, params) => {
  return request({
    url: `/api/user/${id}/agreement/`,
    method: "get",
    params,
  })
}

/** 系统更新用户钱包余额 */
const postChangeWalletAmount = (id, params) => {
  return request({
    url: `/api/user/${id}/wallet/`,
    method: "post",
    params,
  })
}

/** 获取用户全部合同 */
const getUserContracts = (userId, params) => {
  return request({
    url: `/api/user/${userId}/history/`,
    method: "get",
    params,
  })
}

/** 列出用户工资卡记录 */
const getPayCardList = (params) => {
  return request({
    url: "/api/salary/records/",
    method: "get",
    params,
  })
}
/** 导出工资卡记录（打卡预结、提现） */
const getPayCardListExport = (params) => {
  return request({
    url: "/api/salary/records/export/",
    method: "get",
    params,
  })
}

/** 列出用户钱包变动记录 */
const getWalletRecordsList = (params) => {
  return request({
    url: "/api/wallet/records/",
    method: "get",
    params,
  })
}

/** 导出结算用户列表 (公司的员工表格) */
const getPayTemplate = (params) => {
  return request({
    url: "/api/salary/user/export/",
    method: "get",
    params,
  })
}

/** 工资结算预览 (上传公司的员工表格) */
const postPayPreview = (params) => {
  return request({
    url: "/api/salary/user/settlement/preview/",
    method: "post",
    params,
  })
}

/** 列出结算单 (薪酬结算单) */
const getPaySettlementList = (params) => {
  return request({
    url: "/api/salary/settlements/",
    method: "get",
    params,
  })
}

/** 列出结算单下的记录 (薪酬结算单) */
const getPaySettlementDetail = (id, params) => {
  return request({
    url: `/api/salary/settlement/${id}/`,
    method: "get",
    params,
  })
}

/** 工资结算 (提交) */
const postPaySettlement = (params) => {
  return request({
    url: "/api/salary/user/settlement/",
    method: "post",
    params,
  })
}

/** 列出工资条创建记录 */
const getPayrollList = (params) => {
  return request({
    url: `/api/payroll/`,
    method: "get",
    params,
  })
}

/** 列出工资条下工资条目 */
const getPayrollDetail = (id, params) => {
  return request({
    url: `/api/payroll/${id}/`,
    method: "get",
    params,
  })
}

/** 删除工资条（全部） */
const deletePayroll = (id, params) => {
  return request({
    url: `/api/payroll/${id}/`,
    method: "delete",
    params,
  })
}

/** 删除工资条目记录 */
const deletePayrollItem = (id, params) => {
  return request({
    url: `/api/payroll/record/${id}/`,
    method: "delete",
    params,
  })
}

/** 导出工资条模板 (公司的员工表格) */
const getPayrollTemplate = (params) => {
  return request({
    url: `/api/payroll/user/export/`,
    method: "get",
    params,
  })
}

/** 工资条预览 (上传公司的员工表格) */
const postPayrollPreview = (params) => {
  return request({
    url: `/api/payroll/preview/`,
    method: "post",
    params,
  })
}

/** 创建工资条 (提交) */
const postPayroll = (params) => {
  return request({
    url: `/api/payroll/`,
    method: "post",
    params,
  })
}

/** 列出个税记录 (缴纳记录) */
const getTaxPayList = (params) => {
  return request({
    url: `/api/tax/`,
    method: "get",
    params,
  })
}

/** 导出个税记录 (缴纳记录) */
const getTaxPayExport = (params) => {
  return request({
    url: `/api/tax/export/`,
    method: "get",
    params,
  })
}

/** 导出个税申报表模板 (上传公司的员工表格) (上传申报数据) */
const getTaxDeclareTemplate = (params) => {
  return request({
    url: "/api/tax/declare/export/",
    method: "get",
    params,
  })
}

/** 预览个税申报结果 (上传公司的员工表格) (上传申报数据) */
const postTaxDeclarePreview = (params) => {
  return request({
    url: `/api/tax/declare/preview/`,
    method: "post",
    params,
  })
}

/** 导入个税实缴申报 (提交) (上传申报数据) */
const postTaxDeclare = (params) => {
  return request({
    url: `/api/tax/declare/`,
    method: "post",
    params,
  })
}

/** 个税专项抵扣模板下载 (上传月初数据) */
const getTaxDeductTemplate = (params) => {
  return request({
    url: `/api/tax/deduct/template/`,
    method: "get",
    params,
  })
}

/** 预览个税专项扣除导入结果 (上传月初数据) */
const postTaxDeductPreview = (params) => {
  return request({
    url: `/api/tax/deduct/preview/`,
    method: "post",
    params,
  })
}

/** 导入个税专项扣除 (上传月初数据) */
const postTaxDeduct = (params) => {
  return request({
    url: `/api/tax/deduct/`,
    method: "post",
    params,
  })
}

/** 列出提现记录 */
const getWithdrawList = (params) => {
  return request({
    url: `/api/withdraw/`,
    method: "get",
    params,
  })
}

/** 通过/拒绝提现申请 */
const postWithdrawStatus = (id, params) => {
  return request({
    url: `/api/withdraw/${id}/status/`,
    method: "post",
    params,
  })
}

/** 列出用户钱包 */
const getWalletList = (params) => {
  return request({
    url: `/api/wallet/`,
    method: "get",
    params,
  })
}

/** 导出待通过银行提现申请 */
const getWithdrawExport = (params) => {
  return request({
    url: `/api/withdraw/export/`,
    method: "get",
    params,
  })
}

/** 更新打卡日期 */
const putCheckDate = (id, params) => {
  return request({
    url: `/api/test/check_record/${id}/`,
    method: "put",
    params,
  })
}

/** 【临时】老系统用户迁移 */
const postTransferUserChecks = (id, params) => {
  return request({
    url: `/api/user/${id}/transfer/`,
    method: "post",
    params,
  })
}

/** 签署信息校验 */
const verfiySignPdfCreateInfo = (params) => {
  return request({
    url: `/api/agreement/preview/`,
    method: "post",
    params,
  })
}

/** 提交创建签署 */
const createSignPdf = (params) => {
  return request({
    url: `/api/agreement/`,
    method: "post",
    params,
  })
}

/** 列出签署文档 */
const getSignPdfList = (params) => {
  return request({
    url: `/api/agreement/`,
    method: "get",
    params,
  })
}

/** 撤销签署 */
const cancelSignPdf = (id, params) => {
  return request({
    url: `/api/agreement/${id}/cancel/`,
    method: "post",
    params,
  })
}

/**  列出公司的在职用户 */
const getCompanyUserList = (id, params) => {
  return request({
    url: `/api/company/${id}/user/`,
    method: "get",
    params,
  })
}

// /** 更新打卡日期 */
// const putCheckDate = (id, params) => {
//   return request({
//     url: `/api/test/check_record/${id}/`,
//     method: "put",
//     params,
//   })
// }

/** 【测】模拟险金批扣 */
// const getPayInsSystem = (params) => {
//   return request({
//     url: `/api/test/user_ins/process/`,
//     method: "get",
//     params,
//     directReturnResData: true,
//   })
// }

/** `export`的顺序=后端接口文档左侧目录，目的：快速查看缺少啥 (后期可以划分为不同的文件夹) */
export {
  // 账号 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Account
  putAdminPwd, // 修改密码
  putLogin, // 登录
  getAdminInfo, // 获取当前管理员详情
  // 公司 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Company
  getCompanyList, // 列出公司
  postCompany, // 创建公司
  putCompany, // 更新公司
  getStoreList, // 列出公司的子公司
  postStore, // 创建子公司/门店
  putStore, // 更新子公司
  deleteStore, // 删除子公司
  getCompanyUserList, // 列出公司的在职用户
  putSsq, // 注册上上签账号
  // 自定义
  doGetCompanyOptions, // 获取[公司]选项
  doGetCompanyStoreOptions, // 获取[公司]及[门店/子公司]选项
  doGetStoreOptions, // 获取[门店/子公司]选项
  // 职位 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Job
  getJobList, // 列出职位
  getJobListByStore, // 列出子公司职位
  postParentJob, // 创建母职业
  putParentJob, // 更新职位 (母)
  postChildJob, // 创建子职业
  putJobEnable, // 更新职位状态 (子)
  // 自定义
  doGetStoreJobOptions, // 获取[门店/子公司]职位
  // 签约主体 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Entity
  getSignEntityList, // 列出签约主体
  postSignEntity, // 创建签约主体
  putSignEntity, // 更新签约主体
  getSignEntityPayInfo, // 获取签约主体的支付信息
  putSignEntityPayInfo, // 更新签约主体的支付信息
  // 自定义
  doGetSignEntityOptions, // 获取[签约主体]全部选项
  // 险金设置 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_InsConfig
  getPayPlaceList, // 列出险金缴纳地
  getPayPlaceConfig, // 查询某地某年月缴纳配置
  postPayPlace, // 创建险金缴纳地
  postPayPlaceConfig, // 创建缴纳配置
  putPayPlaceConfig, // 更新缴纳配置
  deletePayPlaceConfig, // 删除缴纳配置
  // 自定义
  doGetPayPlaceOptions, // [缴纳地]全部可用选项
  // 合同管理 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Contract
  getContractTemplateList, // 列出合同模板
  postContractTemplate, // 创建合同模板
  putContractTemplate, // 更新合同模板
  getContractList, // 列出合同
  postContract, // 创建合同
  putContract, // 更新合同
  putConfirmContract, // 合同确认签署 (审核签署)
  putRejectContract, // 合同驳回 (驳回重签)
  putGiveUpContract, // 合同放弃签署 (放弃入职)
  putLeaveContract, // 用户离职
  getResignApplicationList, // 列出离职申请
  putRejectResignApplication, // 取消离职申请
  putSureResignApplication, // 确认离职申请
  putContractSalary, // 更新用户合同工资
  // 自定义
  doGetContractTemplateOptions, // 获取[合同模板]全部选项
  // 签约管理
  verfiySignPdfCreateInfo, // 签署信息校验
  createSignPdf, // 提交创建签署
  getSignPdfList, // 列出签署文档
  cancelSignPdf, // 撤销签署
  // 用户管理 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_User
  getUserList, // 列出用户
  getUserDetail, // 获取用户详情
  putUserId, // 更新用户身份信息
  putUserEdu, // 更新用户教育信息
  putUserBank, // 更新用户银行卡信息
  putUserAddress, // 更新用户居住地址
  getUserListCheck, // 列出打卡用户（任务挑战用户）
  putUserInsPay, // 创建或更新用户的社保缴纳信息
  postTransferUserChecks, // 【临时】老系统用户迁移
  putUserCheckLevel, // 更新用户打卡等级
  getCheckList, // 列出用户打卡记录
  getUserSignPdfList, // 列出用户签署文档
  postChangeWalletAmount, // 系统更新用户钱包余额
  getUserContracts, // 获取用户全部合同
  // 险金批扣 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_InsRecord
  getUserListPayIns, // 列出缴纳用户
  getUserListPayInsExport, // 导出缴纳用户
  postInsDeduct, // 创建险金批扣
  getInsDeductList, // 列出险金批扣记录
  getInsDeductListExport, // 导出险金批扣记录
  // 工资相关 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Salary-admin_list_user_salary_records
  getPayCardList, // 列出用户工资卡记录
  getWalletRecordsList, // 列出用户钱包变动记录
  getPayTemplate, // 导出结算用户列表 (公司的员工表格)
  postPayPreview, // 工资结算预览 (上传公司的员工表格)
  postPaySettlement, // 工资结算 (提交)
  getPaySettlementList, // 列出结算单 (薪酬结算单)
  getPaySettlementDetail, // 列出结算单下的记录 (薪酬结算单)
  postPayroll, // 创建工资条 (提交)
  getPayCardListExport, // 导出工资卡记录（打卡预结、提现）
  // 工资条相关 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Payroll
  getPayrollList, // 列出工资条创建记录
  getPayrollDetail, // 列出工资条下工资条目
  deletePayroll, // 删除工资条（全部）
  deletePayrollItem, // 删除工资条目记录
  getPayrollTemplate, // 导出工资条模板 (公司的员工表格)
  postPayrollPreview, // 工资条预览 (上传公司的员工表格)
  // 个税相关 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Tax
  getTaxPayList, // 列出个税记录 (缴纳记录)
  getTaxPayExport, // 导出个税记录 (缴纳记录)
  getTaxDeclareTemplate, // 导出个税申报表模板 (上传公司的员工表格) (上传申报数据)
  postTaxDeclarePreview, // 预览个税申报结果 (上传公司的员工表格) (上传申报数据)
  postTaxDeclare, // 导入个税实缴申报 (提交) (上传申报数据)
  getTaxDeductTemplate, // 个税专项抵扣模板下载 (上传月初数据)
  postTaxDeductPreview, // 预览个税专项扣除导入结果 (上传月初数据)
  postTaxDeduct, // 导入个税专项扣除 (上传月初数据)
  // 提现相关 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Withdraw
  getWithdrawList, // 列出提现记录
  postWithdrawStatus, // 通过/拒绝提现申请
  getWalletList, // 列出用户钱包
  getWithdrawExport, // 导出待通过银行提现申请
  // 其它 https://kuaihuo-mini-dash.interval.im/apidoc/#api-admin_Misc-admin_get_qn_upload_token
  getQnToken, // 获取七牛上传凭证
  putCheckDate, // 更新打卡日期
  // getPayInsSystem, // 【测】模拟险金批扣 // TODO 删除
}
export * from "./statistic"
