/*
 * @Date: 2022-07-27 18:31:36
 * @LastEditTime: 2022-08-26 17:37:42
 */

/** 将[对象]转化为[选项组件]需要的数据结构 {value, label}
 * @param {Object} item 选项对应的[数据对象]
 * @param {Object} [p2] 配置
 * @param {String} [p2.valueKey] 选项[value]对应[数据键名] (默认:sid)
 * @param {String} [p2.labelKey] 选项[label]对应[数据键名] (默认:name)
 * @return {Object} [选项组件]需要的数据结构 {value, label}
 * @description 应用场景: 将[后端数据]转为[选项组件]所需的数据
 */
function itv_get_optionItem(
  item,
  { valueKey = "sid", labelKey = "name" } = {}
) {
  return {
    value: item[valueKey],
    label: item[labelKey],
  }
}

/** 根据[optionArr]获取以选项[value]作为键值的对象[map]
 * @param {Array} optionArr 全部选项配置数组
 * @returns {Object} optionValueMap 以选项[value]作为键值的对象[map]
 */
function itv_get_optionValueMap(optionArr) {
  if (!Array.isArray(optionArr)) {
    console.error("需传入数组")
    return {}
  }
  let optionValueMap = {}
  optionArr.forEach((item) => {
    optionValueMap[item.value] = item
  })
  return optionValueMap
}

/** 根据[optionArr]获取[选项组件]所需的[options]数组
 * @param {Array} optionArr 全部选项配置数组
 * @returns {Array} options [选项组件]所需的[options]数组
 */
function itv_get_options(optionArr) {
  if (!Array.isArray(optionArr)) {
    console.error("需传入数组")
    return []
  }
  let options = optionArr.filter((item) => {
    return !item.isHideInOption
  })
  return options
}

export {
  itv_get_optionItem, // 将[对象]转化为[选项组件]需要的数据结构 {value, label}
  itv_get_optionValueMap, // 根据[optionArr]获取以选项[value]作为键值的对象[map]
  itv_get_options, // 根据[optionArr]获取[选项组件]所需的[options]数组
}
