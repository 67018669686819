/*
 * @Date: 2022-06-14 13:54:45
 * @LastEditTime: 2022-10-07 17:22:59
 */

/** 生成随机字符串
 * @param {Number} length 随机字符串的长度(默认:11)
 * @returns {String} 随机字符串
 */
function itv_get_randomStr(length) {
  let _length = length || 11
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
  let res = ""
  for (let i = 0; i < _length; i++) {
    res += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return res
}

/** 加密字符串
 * @param {Object} p
 * @param {Strng} p.str 待加密字符串
 * @param {Number} p.xor 异或值
 * @param {Number} p.hex 加密后的进制数
 * @return {Strng} 加密后的字符串
 */
function itv_encryptoStr({ str, xor, hex } = {}) {
  if (
    typeof str !== "string" ||
    typeof xor !== "number" ||
    typeof hex !== "number"
  ) {
    return
  }

  let resultList = []

  hex = hex <= 25 ? hex : hex % 25

  for (let i = 0; i < str.length; i++) {
    // 提取字符串每个字符的ascll码
    let charCode = str.charCodeAt(i)
    // 进行异或加密

    charCode = Number(charCode) ^ xor
    // 异或加密后的字符转成 hex 位数的字符串
    charCode = charCode.toString(hex)
    resultList.push(charCode)
  }

  let splitStr = String.fromCharCode(hex + 97)

  let resultStr = resultList.join(splitStr)

  return resultStr
}
/** 解密字符串
 * @param {Object} p
 * @param {Strng} p.str 待加密字符串
 * @param {Number} p.xor 异或值
 * @param {Number} p.hex 加密后的进制数
 * @return {Strng} 加密后的字符串
 */
function itv_decryptoStr({ str, xor, hex } = {}) {
  if (
    typeof str !== "string" ||
    typeof xor !== "number" ||
    typeof hex !== "number"
  ) {
    return
  }
  let strCharList = []

  let resultList = []

  hex = hex <= 25 ? hex : hex % 25
  // 解析出分割字符
  let splitStr = String.fromCharCode(hex + 97)
  // 分割出加密字符串的加密后的每个字符

  strCharList = str.split(splitStr)

  for (let i = 0; i < strCharList.length; i++) {
    // 将加密后的每个字符转成加密后的ascll码
    let charCode = parseInt(strCharList[i], hex)
    // 异或解密出原字符的ascll码

    charCode = Number(charCode) ^ xor
    let strChar = String.fromCharCode(charCode)

    resultList.push(strChar)
  }
  let resultStr = resultList.join("")

  return resultStr
}

/** 支持的[字符串格式化方法名称][type]map
 * @description 方法如下：
 * encryp 加密字符串
 * decryp 解密字符串
 *
 * @description 应用场景
 * 加密/解密字符串 (应用场景: 存储在 localstorage 中的数据看起来安全点)
 */
const ITV_FORMAT_STRING_TYPE_MAP = {
  encryp: itv_encryptoStr,
  decryp: itv_decryptoStr,
}

/** 格式化字符串
 * @param {String} type 字符串格式化方法名称 (取值:[ITV_FORMAT_STRING_TYPE_MAP]的键名)
 * @param {*} params 格式化参数
 * @returns {String} 格式化后的字符串
 */
function itv_formatString(type, params) {
  if (!ITV_FORMAT_STRING_TYPE_MAP[type]) {
    console.error(
      `[type]无效，仅支持${Object.keys(ITV_FORMAT_STRING_TYPE_MAP)}`
    )
    return ""
  }
  return ITV_FORMAT_STRING_TYPE_MAP[type](params)
}

/** 获取字符串的字节长度（中文占2个字节） */
function itv_getStringByteLength(str) {
  if (str === null || str === undefined) {
    return 0
  }
  if (typeof str !== "string") {
    str = String(str)
  }
  // eslint-disable-next-line no-control-regex
  const str2 = str.replace(/[^\x00-\xFF]/g, "01")
  return str2.length
}

export {
  itv_get_randomStr, // 生成随机字符串
  itv_formatString, // 格式化字符串
  itv_getStringByteLength, // 获取字符串的字节长度（中文占2个字节）
}
