/*
 * @Date: 2022-08-05 10:50:21
 * @LastEditTime: 2022-08-31 14:28:23
 */
import { itv_get_randomStr } from "./itv-string"

// 使用示例
// ```
// // utils/qn.js
// import { useItvQn } from "itv-js.js";
// const {
//   /** 获取七牛云上传token */
//   itv_doGetQnToken,
//   /** 上传文件到七牛云 */
//   itv_doUploadQiniu,
// } = useItvQn(qiniu, getQnToken)
// ```

/** [依赖项目npm:qiniu]创建返回itv常用[七牛云上传]逻辑模块
 * @param {Object} qiniu [qiniu-js](https://developer.qiniu.com/kodo/1283/javascript)库 (目的:[itv-js-lib]不依赖于该库，项目按需安装该库)
 * @param {Function} getQnToken 获取[qn-token]的接口请求函数
 * @returns {Object} 常用[七牛云上传]逻辑
 *
 * @description 封装
 * - #逻辑1 itv_doGetQnToken:获取[qn-token]并缓存优化（2分钟不重新请求，减少接口调用）
 * - #逻辑2 itv_doUploadQiniu:上传文件到七牛云
 */
function useItvQn(qiniu, getQnToken) {
  if (!qiniu) {
    console.error("请传入[qiniu]库")
    return {}
  }
  if (typeof getQnToken !== "function") {
    console.error("请传入[getQnToken]接口请求函数")
    return {}
  }

  /** [qn-token]缓存值 */
  let QnToken = ""
  /** [qn-token]过期时间戳 */
  let QnTokenExceedTimeStamp = ""
  /** 几分钟后过期 */
  const EXCEED_MINUTE = 2

  /** 获取七牛云上传token */
  async function itv_doGetQnToken() {
    if (!QnToken || Date.now() > QnTokenExceedTimeStamp) {
      QnToken = ""
      QnTokenExceedTimeStamp = ""
      try {
        let res = await getQnToken()
        QnToken = res.token
        QnTokenExceedTimeStamp = Date.now() + EXCEED_MINUTE * 60 * 1000
      } catch (e) {
        console.error(e)
      }
    }
    return QnToken
  }

  /** 上传文件到七牛云
   * @param {Object} p (必填)
   * @param {String} p.token (必填)
   * @param {File} p.file 被上传的文件 (必填)
   * @param {Array} [p.accept] 支持的文件格式 (选填)
   * @param {String} [p.qnFolder] 上传的七牛云文件夹 (默认:interval) (选填)
   * @param {String} [p.domain] 上传的七牛云的服务器地址 (默认:"https://static.interval.im") (选填)
   * @returns {Object} {err:上传错误 , url:上传成功的url}
   */
  function itv_doUploadQiniu({ token, file, qnFolder, accept, domain } = {}) {
    // console.log({ token, file, qnFolder, accept })
    return new Promise((resolve) => {
      if (!file) return resolve({ err: "file 404" })
      if (!token) return resolve({ err: "token 404" })
      // console.log(file)

      try {
        const fileName = Date.now() + itv_get_randomStr()
        // const fileType = fileType = file.type.split('/')[1]
        // console.log({ fileName, fileType, file })
        const key = `${qnFolder}/${fileName}`
        // const key = `${qnFolder}/${fileName}.${fileType}`

        let _accept = accept
        let observable = qiniu.upload(file, key, token, _accept)
        observable.subscribe({
          next: () => {
            // 正在上传
            // console.log('上传中')
          },
          error: (err) => {
            // 上传失败
            console.log(err)
            return resolve({ err: "上传失败" })
          },
          complete: async (val) => {
            // 上传成功
            let _domain = domain || "https://static.interval.im"
            const url = `${_domain}/${val.key}`
            console.log(`上传七牛云成功: ${url}`)
            return resolve({ url })
          },
        })
      } catch (e) {
        return resolve({ err: e })
      }
    })
  }

  return {
    /** 获取七牛云上传token */
    itv_doGetQnToken,
    /** 上传文件到七牛云 */
    itv_doUploadQiniu,
  }
}

export {
  useItvQn, //[依赖项目npm:qiniu]创建返回itv常用[七牛云上传]逻辑模块
}
