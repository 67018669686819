<!--
 * @Date: 2022-08-16 14:56:24
 * @LastEditTime: 2022-08-16 16:26:11
-->

<!-- [ItvElPageTable.vue]顶部筛选区域对应的按钮(查询) ItvElPageFilterButton itv-el-page-filter-button

逻辑1: 查询
逻辑2: 重置 (暂不支持)
 -->
<template>
  <el-form-item class="itv-el-page-filter-button">
    <!-- <el-button @click="onClearFilterForm">重置</el-button> -->
    <el-button type="primary" @click="_onSearch">查询</el-button>
  </el-form-item>
</template>

<script>
export default {
  name: "ItvElPageFilterButton",
  components: {},
  mixins: [],
  props: {
    /** 查询执行的逻辑 */
    // onSearch: {
    //   type: Function,
    //   required: true,
    // },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    _onSearch() {
      this.$emit("onSearch", { noResetFilter: true })
    },
    /* 页面中[onSearch]的示例如下：
    onSearch() {
      console.log("[onSearch]", { form: this.form })
      this.doGetList({ noResetFilter: true })
    },
      */
  },
}
</script>

<style scoped lang="less">
//.itv-el-page-filter-button {
//}
</style>
