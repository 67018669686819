<!--
 * @Date: 2022-08-17 16:43:46
 * @LastEditTime: 2022-08-26 14:47:57
-->
<!-- 表格单元格 ItvElTableCell itv-el-table-cell

#逻辑1: 计算[dataIndex]对应的数据内容
#逻辑2: 不换行，按需显示气泡提示
 -->
<template>
  <span class="itv-el-table-cell">
    <!-- #逻辑2 -->
    <el-tooltip
      v-if="item.tooltip"
      :content="text"
      placement="top"
      popper-class="itv-table-tooltip"
      :disabled="disableTooltip"
    >
      <span ref="content" class="text-row1 db" @mouseenter="handleTooltipIn">
        {{ text }}
      </span>
    </el-tooltip>
    <!-- #逻辑1 -->
    <span v-else>{{ text }}</span>
  </span>
</template>

<script>
import { itv_get_objectValue } from "@/js/hw-js"

export default {
  name: "ItvElTableCell",
  components: {},
  mixins: [],
  props: {
    /** 该行对应的[数据] */
    row: {
      default() {
        return {}
      },
    },
    /** 该行对应的[自定义列配置] */
    item: {
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      /** 不显示气泡 逻辑2 */
      disableTooltip: true,
    }
  },
  computed: {
    /* #逻辑1 */
    text() {
      return itv_get_objectValue(this.row, this.item.dataIndex)
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /* 移入的时是再判断是否显示气泡 逻辑2 */
    handleTooltipIn() {
      const $content = this.$refs.content
      this.disableTooltip = $content?.scrollWidth <= $content?.offsetWidth
    },
  },
}
</script>

<style scoped lang="less">
//.itv-el-table-cell {
//}
</style>
<style>
.itv-table-tooltip {
  max-width: 300px;
}
</style>
