<!--
 * @Date: 2022-07-30 12:54:33
 * @LastEditTime: 2022-08-02 17:02:35
-->
/* 输入框 ItvElInput itv-el-input */
<template>
  <div class="itv-el-input dib">
    <el-input
      v-if="!readonly"
      v-model="valueInner"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <span v-else>{{ valueInner }}</span>
  </div>
</template>

<script>
// 实现:
// 兼容 readonly 只读 = 组件自带的[只读]属性+自定义只读样式
// 还是封装一层，更好的管理
import {
  mixins_ItvElFormItem_value, // value(:watch) valueInner
} from "@/components/mixins/mixinsItvElInput"

export default {
  name: "ItvElInput",
  components: {},
  mixins: [mixins_ItvElFormItem_value],
  props: {
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>
