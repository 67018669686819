/*
 * @Date: 2022-05-18 10:16:22
 * @LastEditTime: 2022-08-26 12:28:09
 */

/** 支持的[图片]文件格式 */
const ITV_ACCPET_IMG = ["image/png", "image/jpeg", "image/jpg", "image/gif"]

/** 支持的[表格]文件格式
 * @description (itv后端暂时 只支持 .xlsx)
 * .xls 对应 application/vnd.ms-excel
 * .xlsx 对应 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
 */
const ITV_ACCPET_XLSX = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]

/** 将[file]转为[base64]
 * @description (用于:本地图片预览)
 */
function itv_file2Base64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export {
  itv_file2Base64, // 将[file]转为[base64]
  ITV_ACCPET_IMG, // 支持的[图片]文件格式
  ITV_ACCPET_XLSX, // 支持的[表格]文件格式
}
