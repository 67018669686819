/*
 * @Date: 2022-06-14 10:33:44
 * @LastEditTime: 2022-08-31 14:26:03
 */

/** 指向绑定在[window.that]上的当前应用的[vue]实例
 *
 * @description 使用方式
 * 1. 项目中需手动将 `window.that` 赋值指向 `当前应用的 vue 实例`
 * 2. 项目中使用 itv_windowThat 操作 `当前应用的 vue 实例`
 *
 * @description 封装
 * - #逻辑1 `window.that` 未声明报错提示
 * - #目的2 强化 `window.that` 思想（洗脑）
 *
 * @description 原理
 * 1. 使用 [proxy](https://zh.javascript.info/proxy) 代理 `itv_windowThat` 对象
 * 2. 重写 `itv_windowThat` 的获取属性，返回 `window.that`
 */
var itv_windowThat = new Proxy(
  {},
  {
    // 禁止写入
    set: function () {
      return false
    },
    // 逻辑1
    get: function (obj, prop) {
      // console.log(obj, prop)
      if (window.that) {
        return window.that[prop]
      } else {
        console.error(`window.that 404`)
        return ""
      }
    },
  }
)

export {
  itv_windowThat, // 指向绑定在[window.that]上的当前应用的[vue]实例
}
