<!--
 * @Date: 2022-07-30 13:29:08
 * @LastEditTime: 2022-08-16 12:12:25
-->
<!-- 单选框 ItvElRadio itv-el-radio
实现:
  - 封装 options ，直接传入选项，少写html
  - 封装 readonly 只读模式
  - 封装 开关样式
 -->
<template>
  <div class="itv-el-radio">
    <el-radio-group
      v-model="valueInner"
      v-if="!readonly && !isSwitch"
      :disabled="disabled || readonly"
      @change="onOption_ItvElFormComponent"
    >
      <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
      >
        {{ item.label }}
      </el-radio-button>
    </el-radio-group>
    <el-switch
      v-if="!readonly && isSwitch"
      v-model="valueInner"
      active-color="#4581EB"
      @change="onOption_ItvElFormComponent"
    >
    </el-switch>
    <span v-else>{{ valueShow }}</span>
  </div>
</template>

<script>
import {
  getValueLabelByOptions,
  mixins_ItvElFormItem_value, // value(:watch) valueInner
  mixins_ItvElFormItem_onOption, // onOption_ItvElFormComponent($input,$onChange)
} from "@/components/mixins/mixinsItvElInput"

export default {
  name: "ItvElRadio",
  components: {},
  mixins: [mixins_ItvElFormItem_value, mixins_ItvElFormItem_onOption],
  props: {
    /**
     * --- el组件支持的属性 ---
     * https://element.eleme.io/#/zh-CN/component/radio
     */
    /** 禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },

    // --- 组定义属性 ---
    /** [外部值] (覆盖[mixins_ItvElFormItem_value]中的[value]) */
    value: {
      // (注意: Boolean 写在前面，默认值变成 true)
      type: [String, Number, Boolean],
      default: "",
    },
    /** 选项 {value:值, label:显示的名称} */
    options: {
      type: Array,
      default() {
        return []
      },
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
    /** 开关样式(只有两个选项) */
    isSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    /** 选项值对应的文案 */
    valueShow() {
      if (this.readonly) {
        return getValueLabelByOptions({
          options: this.options,
          value: this.value,
        })
      }
      return ""
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.itv-el-radio {
//}
</style>
