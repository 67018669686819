import Vue from "vue"
import VueRouter from "vue-router"
// import { TEST_LOGINED_ROUTES_CHILDREN } from "@/test/router"

// bugfix: 跳转相同的路径 VueRouter 报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "loginPage",
    component: () => import("@/pages/login/loginPage.vue"),
  },
  {
    path: "/",
    name: "layout",
    redirect: { name: "statisticPage" },
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      // 测试
      // ...TEST_LOGINED_ROUTES_CHILDREN,
      // 首页数据看板
      {
        path: "/statistic",
        name: "statisticPage",
        component: () => import("@/pages/statistic/statisticPage.vue"),
      },
      // 新建公司
      {
        path: "/companyCreate",
        name: "companyCreate",
        component: () => import("@/pages/company/companyCreate.vue"),
      },
      // 公司列表
      {
        path: "/companyList",
        name: "companyList",
        component: () => import("@/pages/company/companyList.vue"),
      },
      // 职位列表
      {
        path: "/jobList",
        name: "jobList",
        component: () => import("@/pages/job/jobList.vue"),
      },
      // 注册用户列表
      {
        path: "/userList",
        name: "userList",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/pages/user/userList.vue"),
      },
      // 用户详情
      {
        path: "/userDetail",
        name: "userDetail",
        meta: {
          activeIndex: "userList",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/pages/user/userDetail.vue"),
      },
      // 任务挑战用户
      {
        path: "/userTaskList",
        name: "userTaskList",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/pages/user/userTaskList.vue"
          ),
      },
      /* 劳动合同 */
      // 待签约列表
      {
        path: "/unsignedList",
        name: "unsignedList",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/unsignedList.vue"
          ),
      },
      // 发起签约
      {
        path: "/signCreate",
        name: "signCreate",
        meta: {
          activeIndex: "unsignedList",
        },
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/signCreate.vue"
          ),
      },
      // 编辑签约
      {
        path: "/signEdit",
        name: "signEdit",
        meta: {
          activeIndex: "unsignedList",
        },
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/signEdit.vue"
          ),
      },
      // 待审核列表
      {
        path: "/unverfiyList",
        name: "unverfiyList",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/unverfiyList.vue"
          ),
      },
      // 入职审核
      {
        path: "/contractVerify",
        name: "contractVerify",
        meta: {
          activeIndex: "unverfiyList",
        },
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/contractVerify.vue"
          ),
      },
      // 已签约列表
      {
        path: "/signedList",
        name: "signedList",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/signedList.vue"
          ),
      },
      // 申请离职
      {
        path: "/resignApplicationList",
        name: "resignApplicationList",
        component: () =>
          import(
            /* webpackChunkName: "contract" */ "@/pages/contract/resignApplicationList.vue"
          ),
      },
      /* 签约管理 */
      // 服务商主体管理
      {
        path: "/signEntityList",
        name: "signEntityList",
        component: () =>
          import(
            /* webpackChunkName: "sign" */ "@/pages/contract/signEntityList.vue"
          ),
      },
      // 合同模板管理
      {
        path: "/contractTemplateList",
        name: "contractTemplateList",
        component: () =>
          import(
            /* webpackChunkName: "sign" */ "@/pages/contract/contractTemplateList.vue"
          ),
      },
      // 签约文档
      {
        path: "/signPdfList",
        name: "signPdfList",
        component: () =>
          import(
            /* webpackChunkName: "sign" */ "@/pages/contract/signPdfList.vue"
          ),
      },
      // 签约文档 发起签约
      {
        path: "/signPdfCreate",
        name: "signPdfCreate",
        component: () =>
          import(
            /* webpackChunkName: "sign" */ "@/pages/contract/signPdfCreate.vue"
          ),
      },
      /* 险金管理 */
      // 缴纳比例
      {
        path: "/insPayProportionList",
        name: "insPayProportionList",
        component: () =>
          import(
            /* webpackChunkName: "ins" */ "@/pages/ins/insPayProportionList.vue"
          ),
      },
      // 缴纳人员
      {
        path: "/insPayUserList",
        name: "insPayUserList",
        component: () =>
          import(
            /* webpackChunkName: "ins" */ "@/pages/ins/insPayUserList.vue"
          ),
      },
      // 批扣记录
      {
        path: "/insDeductList",
        name: "insDeductList",
        component: () =>
          import(/* webpackChunkName: "ins" */ "@/pages/ins/insDeductList.vue"),
      },
      /* 薪酬管理 */
      // 打卡日结记录
      {
        path: "/payDailyList",
        name: "payDailyList",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/pages/pay/payDailyList.vue"),
      },
      // 薪酬结算
      {
        path: "/paySettlementList",
        name: "paySettlementList",
        component: () =>
          import(
            /* webpackChunkName: "pay" */ "@/pages/pay/paySettlementList.vue"
          ),
      },
      // 薪酬结算[创建结算单]
      {
        path: "/paySettlementCreate",
        name: "paySettlementCreate",
        meta: {
          activeIndex: "paySettlementList",
        },
        component: () =>
          import(
            /* webpackChunkName: "pay" */ "@/pages/pay/paySettlementCreate.vue"
          ),
      },
      // 薪酬结算[查看详情]
      {
        path: "/paySettlementDetail",
        name: "paySettlementDetail",
        meta: {
          activeIndex: "paySettlementList",
        },
        component: () =>
          import(
            /* webpackChunkName: "pay" */ "@/pages/pay/paySettlementDetail.vue"
          ),
      },
      // 工资条
      {
        path: "/payrollList",
        name: "payrollList",
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/pages/pay/payrollList.vue"),
      },
      // 工资条 创建
      {
        path: "/payrollCreate",
        name: "payrollCreate",
        meta: {
          activeIndex: "payrollList",
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/pages/pay/payrollCreate.vue"),
      },
      // 工资条 详情
      {
        path: "/payrollDetail",
        name: "payrollDetail",
        meta: {
          activeIndex: "payrollList",
        },
        component: () =>
          import(/* webpackChunkName: "pay" */ "@/pages/pay/payrollDetail.vue"),
      },
      // 个人所得税
      {
        path: "/taxPayList",
        name: "taxPayList",
        component: () =>
          import(/* webpackChunkName: "tax" */ "@/pages/tax/taxPayList.vue"),
      },
      // [个人所得税][上传申报数据]
      {
        path: "/taxPayUpload",
        name: "taxPayUpload",
        meta: {
          activeIndex: "taxPayList",
        },
        component: () =>
          import(/* webpackChunkName: "tax" */ "@/pages/tax/taxPayUpload.vue"),
      },
      // [个人所得税][上传月初数据]
      {
        path: "/taxReduceUpload",
        name: "taxReduceUpload",
        meta: {
          activeIndex: "taxPayList",
        },
        component: () =>
          import(
            /* webpackChunkName: "tax" */ "@/pages/tax/taxReduceUpload.vue"
          ),
      },
      // 工资转出
      {
        path: "/payCardTransferList",
        name: "payCardTransferList",
        component: () =>
          import(
            /* webpackChunkName: "pay" */ "@/pages/pay/payCardTransferList.vue"
          ),
      },
      /* 提现管理 */
      // 提现申请列表
      {
        path: "/withdrawApplyList",
        name: "withdrawApplyList",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "@/pages/wallet/withdrawApplyList.vue"
          ),
      },
      // 提现列表
      {
        path: "/withdrawRecordList",
        name: "withdrawRecordList",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "@/pages/wallet/withdrawRecordList.vue"
          ),
      },
      // 工资转出
      {
        path: "/userWalletList",
        name: "userWalletList",
        component: () =>
          import(
            /* webpackChunkName: "wallet" */ "@/pages/wallet/userWalletList.vue"
          ),
      },
    ],
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("@/pages/notFound/notFound.vue"),
  },
  {
    path: "/*",
    redirect: { name: "notFound" },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
