/*
 * @Date: 2022-05-23 17:32:08
 * @LastEditTime: 2022-08-15 16:18:14
 */
/* 复用 ElUpload 逻辑

import mixinsItvElUpload from "@/components/mixins/mixinsItvElUpload" // emit:change list(watch) multiple maxCount fileList(watch) isShowUploadBtn onDelete disabled readonly _disabled

 */

import { showError } from "@/js/itv-js-el"

export default {
  // https://cn.vuejs.org/v2/api/#model
  model: {
    prop: "list",
    event: "change",
  },
  props: {
    /** 已上传的文件(外部值) */
    list: {
      type: Array,
      default: null,
    },
    /** 支持一次性多选 (!部分安卓机不支持) */
    multiple: {
      type: Boolean,
      default: false,
    },
    /** 文件上传数量上限 */
    maxCount: {
      type: Number,
      default: 1,
    },
    /** 禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** 已上传的文件(内部值)
       * @description 数据格式:
       * {
       *   url: 网络图片地址,
       *   preview: base64预览地址,
       *   originFileObj: 原文件用于上传,
       *   name: 文件名称
       * }
       */
      fileList: [],
      isShowUploadBtn: false, // 显示 上传按钮
      debug: true, // 开启调试打印
    }
  },
  computed: {
    _disabled() {
      return this.readonly || this.disabled
    },
  },
  watch: {
    // list 和 fileList 保持一致
    list: {
      handler() {
        this.updateInnerByOuter()
      },
      immediate: true,
    },
  },

  methods: {
    /** [删除]图片/文件*/
    onDelete(i) {
      if (!this.disabled) {
        this.fileList.splice(i, 1)
        this.updateVModel(this.list, `删除文件${i}`)
      }
    },
    /** 是否显示[上传按钮] */
    setIsShowUploadBtn() {
      let isShow = false
      const length = this.fileList.length
      if (length < this.maxCount) {
        isShow = true
      }
      this.isShowUploadBtn = isShow
      this.debug && console.log(`[上传按钮]${isShow ? "" : "不"}显示`)
    },
    /** 将[内部值]同步更新为[外部值] */
    updateInnerByOuter() {
      let v = this.list
      if (v === this.fileList) {
        // console.log("内外值相同无需更新")
        return
      }

      if (v && v instanceof Array && v.length) {
        if (v.length > this.maxCount) {
          showError(`[外部值]长度${v.length}大于[maxCount]=${this.maxCount}`)
          this.updateVModel([], "[外部值]数据无效，重置为空数组")
          return
        }
        this.updateVModel(this.list, "[外部值]非空，同步[内部值]为[外部值]")
      } else {
        this.updateVModel(this.fileList, "[外部值]为空，同步[外部值]为[内部值]")
      }
    },
    /** 更新组件v-model值(内部值及外部值)
     * @description 目的: 手动监听变化，更可控
     */
    updateVModel(v = [], sceneZh = "未传入修改原因") {
      this.fileList = v || []
      // bugfix: 内部 fileList 数据未更新 (不知道为啥)
      this.$forceUpdate()
      this.$emit("change", this.fileList) // 更新[外部值]
      this.setIsShowUploadBtn()
      this.debug && console.log(`更新[内部值]原因: ${sceneZh}`)
    },
  },
}
