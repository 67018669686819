<!-- [公司][子公司][签约岗位]联动选择器 HwSelectJob hw-select-job -->
<template>
  <div class="hw-select-job">
    <HwSelectCompany
      v-model="form.company"
      :initLabel="label.company"
      :storeOptions.sync="options.store"
      :readonly="readonly"
      placeholder="请输入公司名称，进行选择"
      :clearable="true"
      @onChange="clearForm"
    />
    <div>
      <ItvElSelect
        v-model="form.store"
        placeholder="请选择子公司/门店"
        :options="options.store"
        @onChange="clearFromJob"
        v-show="isShow.store"
        :readonly="readonly"
        :clearable="true"
        :class="{ mt8: !readonly }"
      />
    </div>
    <ItvElSelect
      v-model="form.job"
      placeholder="请选择职位"
      :options="options.job"
      v-show="isShow.job"
      :class="{ mt8: !readonly }"
      :readonly="readonly"
      :clearable="true"
      @onChange="onJob"
    />
  </div>
</template>

<script>
import HwSelectCompany from "@/components/HwSelectCompany.vue"
import ItvElSelect from "@/components/ItvElSelect.vue"
import { doGetStoreJobOptions } from "@/api"

export default {
  name: "HwSelectJob",
  components: { HwSelectCompany, ItvElSelect },
  mixins: [],
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    initLabel: {
      type: Array,
      default() {
        return []
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** [签约岗位]组合form */
      form: {
        company: "", // 签约岗位 公司 sid
        store: "", // 签约岗位 子公司/门店 sid
        job: "", // 签约岗位 职位sid
      },
      label: {
        company: "", // 签约岗位 公司 名称 (兼容 ItvElSelect 组件，最开始无选项)
      },

      // 表单选项
      options: {
        store: [],
        job: [],
      },
    }
  },
  computed: {
    isShow() {
      return {
        store: this.form.company,
        job: this.form.store,
      }
    },
    valueInner() {
      const { company, store, job } = this.form
      return [company, store, job]
    },
  },
  watch: {
    // 监听 输入框值 变化 外部 改 内部
    value: {
      handler(v) {
        this.form.company = v?.[0] || ""
        this.form.store = v?.[1] || ""
        this.form.job = v?.[2] || ""
      },
      immediate: true,
    },
    // (兼容 ItvElSelect 组件，最开始无选项)
    initLabel: {
      handler(v) {
        // console.log(`[HwSelectJob] 初始化选项 label`, { ov: v })
        this.label.company = v?.[0] || ""
        const store = v?.[1] || ""
        const job = v?.[2] || ""
        if (store && this.form.store) {
          this.options.store = [{ label: store, value: this.form.store }]
        }
        if (job && this.form.job) {
          this.options.job = [{ label: job, value: this.form.job }]
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    /** 清空重置[form] */
    clearForm() {
      this.form.store = ""
      this.form.job = ""
      this.options.job = []
    },
    /** 清空重置[form][job] */
    clearFromJob() {
      this.form.job = ""
      this.options.job = []
      this.form.store && this.doGetStoreJobOptions()
    },
    onJob() {
      const value = this.valueInner
      this.$emit("input", value) // 更新 输入框值
      // this.$emit("onChange", value)
    },
    /** 获取[job]选项 */
    async doGetStoreJobOptions() {
      const storeId = this.form.store
      let options = []
      try {
        options = await doGetStoreJobOptions({ storeId })
      } catch (e) {
        console.error(e)
      }
      this.options.job = options
    },
  },
}
</script>

<style scoped lang="less">
//.hw-select-job {
//}
</style>
