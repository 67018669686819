<!--
 * @Date: 2022-05-06 17:05:15
 * @LastEditTime: 2022-08-08 11:03:12
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style lang="less"></style>
