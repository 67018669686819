/* 基于 el 图片上传(建议作为单图使用) ItvElUploadImg itv-el-upload-img */
<template>
  <el-upload
    action=""
    :file-list="fileList"
    :multiple="multiple"
    :before-upload="onBeforeUpload"
    :limit="maxCount"
    :show-file-list="false"
    :accept="accept.join(',')"
    class="itv-el-upload-img"
    :disabled="_disabled"
  >
    <!-- 上传按钮 (位置只能在最前面) -->
    <template #trigger>
      <div class="item-wrap" v-if="isShowUploadBtn && !readonly">
        <div class="item-upload">
          <i class="el-icon-plus"></i>
          <div>{{ addText }}</div>
        </div>
      </div>
      <div v-else></div>
    </template>
    <!-- 已上传的图片 (支持:查看大图+删除) -->
    <template v-if="fileList.length">
      <div class="item-wrap" v-for="(file, i) in fileList" :key="i">
        <el-image
          class="item-img"
          :src="file.url || file.preview"
          :preview-src-list="previewSrcList"
          fit="contain"
        />
        <i
          v-if="!_disabled"
          class="el-icon-error item-btn-delete cp"
          @click="onDelete(i)"
        ></i>
      </div>
    </template>
    <template v-if="!fileList.length">
      <span></span>
    </template>
  </el-upload>
  <!-- </div> -->
</template>

<script>
import { ITV_ACCPET_IMG } from "@/js/hw-js"
import { showError } from "@/js/itv-js-el"
import mixinsItvElUpload from "@/components/mixins/mixinsItvElUpload" // emit:change list(watch) multiple maxCount fileList(watch) isShowUploadBtn onDelete disabled readonly _disabled

export default {
  name: "ItvElUploadImg",
  components: {},
  mixins: [mixinsItvElUpload],
  props: {
    // 支持的图片格式
    accept: {
      type: Array,
      default() {
        return ITV_ACCPET_IMG
      },
    },
    // 加号下的文案
    addText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  computed: {
    // 预览图片列表
    previewSrcList() {
      return this.fileList.map((item) => item.url || item.preview || "")
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 上传文件 之前 校验 ，并返回 false 自定义上传 (上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。)
    onBeforeUpload(file) {
      // console.log("onBeforeUpload")
      // 文件类型 file.type 如 'image/jpeg','image/png'
      // 文件大小 file.size

      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        showError("图片需要小于 2MB!")
      } else {
        // 直接修改 fileList 不上传文件
        this.fileList = [
          ...this.fileList,
          {
            name: file.name,
            preview: URL.createObjectURL(file),
            url: "",
            originFileObj: file,
          },
        ]
        this.updateVModel(this.fileList, "input上传文件")
        console.log("上传成功", file)
      }

      // 返回 false 则停止上传
      return false
    },
  },
}
</script>

<style scoped lang="less">
.itv-el-upload-img {
  // line-height: 0;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  @size-img: 104px;
  @size-delete: 20px;

  .item-upload,
  .item-img,
  .item-wrap {
    height: @size-img;
    width: @size-img;
    // line-height: calc(@size-img - 2px);
    border-radius: 8px;
  }
  .item-wrap {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 8px;
    position: relative;
  }
  .item-upload {
    border: 1px dashed #c0ccda;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .item-img {
    border: 1px solid #c0ccda;
  }
  .item-btn-delete {
    position: absolute;
    font-size: @size-delete;
    top: calc(@size-delete / 3 * -1);
    right: calc(@size-delete / 3 * -1);
    color: #333;
    transition: 0.3s opacity;
    display: none;
    background: #ccc;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
    }
  }
  .item-wrap:hover .item-btn-delete {
    display: block;
  }
}
// 兼容 表单 报错
.is-error {
  .item-upload,
  .item-img {
    border-color: #f56c6c;
    color: #f56c6c;
  }
}
</style>
