/*
 * @Date: 2022-06-14 10:32:48
 * @LastEditTime: 2022-09-05 14:01:57
 */

/** [根据项目]创建返回[consoleDev]函数实例
 * @param {Object} p 打印配置
 * @param {String} [p.bgDefault] 普通打印的背景色 (默认:#00ffff20)
 * @param {String} [p.bgImportant] 重要打印的背景色 (默认:#ff000050)
 * @param {String} [p.preText] 打印前缀 (应用场景: 微前端多应用，加上前缀区分打印)
 * @param {String} [p.color] 文字的颜色(默认:不设置)
 * @param {Boolean} [p.disableConsoleDev] 禁止[consoleDev]打印(默认:不禁用) (应用场景: 生产环境)
 * @returns {Function} [consoleDev]实例函数
 *
 * @example 使用示例
 * import { ConsoleDev } from "@/utils/itv-js.js";
 * const consoleDev = ConsoleDev({ preText: "主应用" });
 * export { consoleDev };
 *
 * @description 应用场景: 微信开发者工具中，微信自身会打印很多信息，使用 consoleDev 可以快速看到想看的调试打印。
 */
function itv_consoleDev_constructor({
  bgDefault,
  bgImportant,
  preText,
  color,
  disableConsoleDev,
} = {}) {
  // 禁止[consoleDev]打印
  if (disableConsoleDev) {
    return () => {}
  }

  // 配置[consoleDev]打印样式
  let bg_d = bgDefault || "#00ffff20"
  let bg_i = bgImportant || "#ff000050"
  let text = preText ? `[${preText}]` : ""
  let text_color = color ? `color:${color}` : ""
  /** consoleDev 实例函数
   * @typedef {Function} consoleDev
   * @param {String} v 打印的信息
   * @param {Object} [p2] 打印其他信息
   * @param {Boolean} [p2.im] 是否醒目打印，多用于异常打印 (im = isImportant)
   * @param {String} [p2.ov] 打印的其他信息 (ov = otherValue)
   */
  const consoleDev = (v, { im, ov } = {}) => {
    const bgColor = im === true ? bg_i : bg_d
    const otherV = typeof ov === "undefined" ? "" : ov
    console.log(
      `%c${text}%s`,
      `background: ${bgColor};${text_color}`,
      v,
      otherV
    )
  }

  return consoleDev
}

export {
  itv_consoleDev_constructor, // [根据项目]创建返回[consoleDev]函数实例
}
