/*
 * @Date: 2022-08-05 11:40:08
 * @LastEditTime: 2022-08-26 15:19:13
 */
// - [lodash JS 工具库@4.x](https://www.lodashjs.com/)
import lodash from "lodash"

import { itv_Fen2Yuan, useItvLodash } from "@/js/itv-js"

const {
  /** 根据[设置值]和[默认值]，返回[数组] */
  itv_safeArray,
  /** 按照对象的[数据格式对象]格式化对象 */
  itv_safeObject,
  /** 根据[对象][键名字符串](可包含.)，返回对应的[键值] */
  itv_get_objectValue,
} = useItvLodash(lodash)

function getSafeString(v) {
  return lodash.isNil(v) ? "" : String(v)
}

/** 将[非空值(金额)(分)/100]转为[字符串(元)]
 * @param {String/Number} v 值
 * @returns {String} 字符串(元)
 */
function getSafeFen2Yuan(v) {
  return lodash.isNil(v) ? "" : itv_Fen2Yuan(v ?? "")
}

/** 获取[上传组件]需要的[数据格式] [{url, name}]
 * @param {String} v 文件路径url
 */
function getSafeFileArr(v) {
  return v ? [{ url: v, name: String(Date.now()) }] : []
}

export {
  getSafeString,
  /** 根据[设置值]和[默认值]，返回[数组] */
  itv_safeArray,
  /** 根据[数据格式]对象，格式化并返回对象 */
  itv_safeObject,
  /** 根据[对象][键名字符串](可包含.)，返回对应的[键值] */
  itv_get_objectValue,
  getSafeFen2Yuan,
  getSafeFileArr,
}
