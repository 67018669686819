/** 业务逻辑文案 */
const HW_LOGIC = {
  /** [打卡任务][离职处理]:用户离职后，如果有进行中的打卡任务，该任务取消。如果钱包里有冻结的余额，冻结的余额清零 */
  resign_check: "用户离职后，取消进行中的打卡任务，钱包冻结余额清零。",
  /** [险金缴纳][离职处理]:(险金管理\缴纳人员)这个页面展示所有有代缴纳险金的人员，如果员工已经离职，但他有未缴纳的险金，那么依然显示在这个列表里。 */
  resign_insPay: "用户离职后，有未缴纳的险金，“缴纳人员列表”中会显示该用户。",
  /** 页面表格中的“开始缴纳月份”指的是该员工入职后，第一次被批扣的月份。 */
}

export { HW_LOGIC }
