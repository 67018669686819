/*
 * @Date: 2022-08-05 11:36:58
 * @LastEditTime: 2022-08-29 11:07:11
 */
/* 接口请求封装

接口使用示例：
    const REQUEST_DATA = {
      method: "get/post/put/delete/head/options", // 接口类型
      url: "", // 接口url
      name: "", // 接口描述
      hideError: " true/false", // 不自动报错
      codeArrHideError: " [number]", // 不自动报错的code
      directReturnResData: " true/false", // 不根据code校验响应结果，且直接返回相应结果data
      params: {}, // 请求参数
      headers: {}, // 请求头
    }
 */

import axios from "axios" // http://www.axios-js.com/zh-cn/docs/
import { itv_getCacheToken, itv_setCacheToken } from "./hw-token"
import { useItvReuqest } from "@/js/itv-js"
import { showError } from "@/js/itv-js-el"

// 全局报错，无需再页面中手动报错
const showErrorMsg = (msg) => {
  console.log(`[请求报错]: ${msg}`)
  showError(msg)
}

const {
  /** axios接口请求实例 */
  request,
} = useItvReuqest(axios, {
  // baseURL:
  //   process.env.VUE_APP_ENV === "development"
  //     ? process.env.VUE_APP_API_URL
  //     : "",
  codeArrNoAuth: [1101],
  codeSuccess: 0,
  showError: showErrorMsg,
  getToken: itv_getCacheToken,
  clearToken: itv_setCacheToken,
  cbNoAuth: () => {
    window.location.href = `${window.location.origin}/login`
  },
})

export {
  /** axios接口请求实例
   */
  request,
}
