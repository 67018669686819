/** [columns]列配置信息，保持统一
 * - 80px = 4个中文字
 */
const HW_COLUMNS = {
  userName: {
    title: "姓名",
    width: "100px",
    fixed: "left",
    dataIndex: "name",
  },
  mobile: {
    title: "手机号",
    width: "100px",
    dataIndex: "mobile",
  },
  text180: {
    minWidth: "180px",
  },
  text160: {
    minWidth: "160px",
  },
  text120: {
    minWidth: "120px",
  },
  text100: {
    minWidth: "100px",
  },
  text80: {
    minWidth: "80px",
  },
  dateShort: {
    width: "110px",
  },
  dateLong: {
    width: "150px",
  },
}

export { HW_COLUMNS }
