/*
 * @Date: 2022-08-04 17:19:59
 * @LastEditTime: 2022-08-31 14:27:01
 */

/** [依赖项目npm](lodash)创建返回itv常用[数据处理]逻辑模块
 * @description 目的: 逻辑较多，统一导出
 * @param {Object} lodash [lodash@4.x](https://www.lodashjs.com/)库 (目的:[itv-js-lib]不依赖于该库，项目按需安装该库)
 * @returns {Object} 常用[数据处理]逻辑
 *
 * @example
 * const { itv_get_objectValue } = useItvLodash(lodash)
 *
 * @description 封装逻辑模块
 * - itv_safeObject: 根据[设置值]和[默认值]，返回[新对象]
 * - itv_safeArray: 根据[设置值]和[默认值]，返回[数组]
 * - itv_get_objectValue: 根据[对象键名路径]返回[对象]对应的[键值]
 *
 * @description 应用场景
 * 1. 前端组件的数据类型为`字符串`，后端给的是`数字`
 * 2. 后端组件有的数据返回null，前端null肯会报错，需要处理为组件需要的数据格式
 */
function useItvLodash(lodash) {
  if (!lodash) {
    console.error("请传入[dayjs]")
    return {}
  }

  /** 根据[设置值]和[默认值]，返回[数组]
   * @param {Array} v 设置值
   * @param {Array} [vDefault] 默认值
   * @returns v/vDefault/空数组
   */
  function itv_safeArray(v, vDefault) {
    let res = v ?? vDefault ?? []
    if (!lodash.isArray(res)) {
      res = []
    }
    return res
  }

  /** 根据[设置值]和[默认值]，返回[新对象]
   * @param {Object} v 需格式化的对象
   * @param {Object} vDefault 对象的[数据格式对象] (默认值)
   * @returns {Object} [格式化后的对象] (全新对象)
   *
   * @description 封装
   * - #逻辑1 若[v]的键值为null/undefined，则取[vDefault]的值
   *
   * @description 应用场景: `前端表单组件数据`和`后端接口数据`的合并
   */
  function itv_safeObject(v, vDefault) {
    if (!lodash.isPlainObject(v) || !lodash.isPlainObject(vDefault)) {
      console.error("参数必须是普通对象")
      return {}
    }

    let vdemo = vDefault
    let res = lodash.cloneDeep(vdemo)

    // 遍历[数据格式]
    Object.keys(res).forEach((formKey) => {
      res[formKey] = lodash.cloneDeep(v[formKey]) ?? vdemo[formKey] // #逻辑1
    })
    return res
  }

  /** 根据[对象键名路径]返回[对象]对应的[键值]
   * @param {Object} obj 要检索的对象
   * @param {String} vPath 对象键名路径(可包含.)
   * @return {*} v 键值
   * @description #逻辑1 键值=[null/undefined]会返回空字符串
   * @description 应用场景: 表格展示数据处理，即通过键名路径获取键值
   */
  function itv_get_objectValue(obj, vPath) {
    return lodash.get(obj, vPath, "")
  }

  return {
    /** 根据[设置值]和[默认值]，返回[新对象] */
    itv_safeObject,
    /** 根据[设置值]和[默认值]，返回[数组] */
    itv_safeArray,
    /** 根据[对象键名路径]返回[对象]对应的[键值] */
    itv_get_objectValue,
  }
}

export {
  useItvLodash, // [依赖项目npm](lodash)创建返回itv常用[数据处理]逻辑模块
}
