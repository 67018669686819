import { request } from "@/js/hw-js/hw-request"

const getStatisticOverall = (params) => {
  return request({
    url: "/api/data/",
    name: "获取数据看板",
    method: "get",
    params,
  })
}

// const getStatisticSingle = (params) => {
//   return request({
//     url: "/api/statistic/single",
//     name: "获取单项指标",
//     method: "get",
//     params,
//   })
// }

const getStatisticSingleExport = (params) => {
  return request({
    url: "/api/data/export/",
    name: "全部指标导出",
    method: "get",
    params,
  })
}

export {
  getStatisticOverall, // 获取数据看板
  // getStatisticSingle, // 获取单项指标
  getStatisticSingleExport, // 单项指标导出
}
