/*
 * @Date: 2022-08-05 11:39:41
 * @LastEditTime: 2022-08-05 15:20:10
 */

import { itv_consoleDev_constructor } from "@/js/itv-js"

const consoleDev = itv_consoleDev_constructor({
  disableConsoleDev: process.env.NODE_ENV === "production",
})

export { consoleDev }
