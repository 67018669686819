/* 页面是列表类型 ItvElPageTable itv-el-page-table */
<!-- 
ItvElPageTable 封装 

封装: 列表数据的获取

一、 内部封装 (不变的:执行的逻辑相同)
  list:列表数据 
  isDoing:正在获取数据 (v-loading)
  pagination:分页组件+js逻辑
  ItvElTable:列表组件
  doGetList:列表数据获取
  filterLast: 上次[filter]值 (目的:未点击“查询”确认使用最新[filter]，则重置为上次[filter])

二、 外部可配置JS pageConfig (变的:每个页面不同)
  doGetListMethod: {Function} 获取列表数据的接口 (必传)
  formatList: {Function}  获取列表数据(list)后的回调函数，传入对象list加工修改后，必须再返回list (选传)
  pageTitle: {String} 页面左上角标题 (选传)
    (slot:pageTitleSuffix) 标题后的插槽 (如:提示组件)
  setFilter: {Function} 设置[列表页面]筛选参数[filter]对应数据 (内部重置表单数据为上一次结果) (选传)
  getFilter: {Function} 获取[列表页面]筛选参数[filter]对应数据 (选传)
  columns: {Array} 列表列的配置，具体配置查看HwTable组件 (必传)
  listNameApi: {String} 列表数据-对应的-后端接口返回数据-键名 (必传)
  

三、 外部可配置HTML slot (变的:每个页面不同)
  bodyCell: 特殊的表格单元格
 -->

<!-- FIXME (2023-11-10) 这么封装不好，导致 表格样式 无法复用 -->
<template>
  <div class="itv-el-page-table" v-loading="isDoing">
    <ItvElPageTableH1 v-if="pageTitle">
      {{ pageTitle }}
      <template #suffix>
        <slot name="pageTitleSuffix"></slot>
      </template>
    </ItvElPageTableH1>

    <slot name="pageHeader"></slot>

    <ItvElTable
      ref="refHwTable"
      :list="list"
      :columns="columns"
      :rowKey="rowKey"
      :treeProps="treeProps"
      :default-expand-all="defaultExpandAll"
      :size="size || 'small'"
      @row-click="rowClick"
    >
      <template #bodyCell="{ row, dataIndex }">
        <slot name="bodyCell" v-bind:row="row" v-bind:dataIndex="dataIndex">
        </slot>
      </template>
    </ItvElTable>

    <ItvElPagination :pagination="pagination" @onPageCurrent="doGetList" />
  </div>
</template>

<script>
import ItvElPageTableH1 from "@/components/ItvElPageTable/ItvElPageTableH1.vue"
import ItvElTable from "@/components/ItvElTable/ItvElTable.vue"
import ItvElPagination from "@/components/ItvElPagination.vue"
import mixinsItvElTable from "@/components/ItvElTable/mixinsItvElTable"

export default {
  name: "ItvElPageTable",
  components: { ItvElTable, ItvElPageTableH1, ItvElPagination },
  mixins: [mixinsItvElTable],
  props: {
    // 具体参数，查看："外部可配置JS"
    pageConfig: {
      typeof: Object,
      required: true,
      validator(v) {
        // console.log(v)
        if (!v) return false
        if (typeof v.doGetListMethod !== "function") return false
        if (!Array.isArray(v.columns)) return false
        return true
      },
    },
  },
  data() {
    return {
      list: [],
      isDoing: false,
      // pageConfig 解构出如下值
      doGetListMethod: null,
      idDoGetList: "",
      formatList: null,
      pageTitle: null,
      setFilter: null,
      getFilter: null,
      getFilterApiParams_outter: null,
      filterLast: null, // 上次的[filter]
      columns: null,
      listNameApi: null,

      /** 分页 */
      pagination: {
        page: 1, // 第几页
        per_page: 10, // 每页几条
        total: 0,
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.parsePageConfig()
    this.doGetList()

    // 判断是否传入 slot
    // console.log(this.$slots.pageHeader)
  },
  methods: {
    emptyFunction() {},
    /** 解析[pageConfig]中需要的参数 */
    parsePageConfig() {
      this.doGetListMethod = this.pageConfig.doGetListMethod
      this.columns = this.pageConfig.columns.filter((item) => !item.isHide)
      this.listNameApi = this.pageConfig.listNameApi
      this.idDoGetList = this.pageConfig.idDoGetList
      this.formatList = this.pageConfig.formatList
      this.pageTitle = this.pageConfig.pageTitle
      this.setFilter = this.pageConfig.setFilter
      this.getFilter = this.pageConfig.getFilter
      this.getFilterApiParams_outter = this.pageConfig.getFilterApiParams
      this._set_filterLast()
    },
    /** 兼容: [获取]外部[filter] */
    _getFilter() {
      let filter = {}
      if (this.getFilter) {
        filter = this.getFilter()
      }
      return filter || {}
    },

    /** 重置筛选[filter]为[filterLast] */
    _setFilterByFilterLast() {
      if (this.setFilter) {
        this.setFilter({ ...this.filterLast })
      }
    },
    /** 更新[filterLast]值 */
    _set_filterLast() {
      let filter = this._getFilter()
      this.filterLast = { ...filter }
    },

    /** 获取列表筛选[参数] (目的: 兼容外部获取) */
    getFilterApiParams() {
      let filter = this._getFilter()
      if (this.getFilterApiParams_outter) {
        filter = this.getFilterApiParams_outter({ ...filter })
      }
      return filter
    },

    /** 获取列表
     * @param {Object} p0 参数
     * @param {Object} p0.page 页码 (应用场景: 分页器组件触发)
     * @param {Object} p0.noResetFilter 不重置[filter]为上次有效值 (应用场景: 筛选插槽触发查询)
     * @param {Object} p0.noResetPage 不重置[page] (应用场景: 停用/启用后，查看结果)
     */
    async doGetList({
      page = 1,
      noResetFilter = false,
      noResetPage = false,
    } = {}) {
      // 执行 doGetList
      this.isDoing = true
      let list = []
      let total = 0

      if (!noResetPage) {
        this.pagination.page = page // 设置分页
      }

      if (!noResetFilter) {
        this._setFilterByFilterLast()
      }
      this._set_filterLast()
      let filter = this.getFilterApiParams()
      const p = this.pagination || {}
      let params = {
        ...filter,
        page: p.page,
        per_page: p.per_page,
      }

      try {
        const p1 = this.idDoGetList || params
        const p2 = this.idDoGetList ? params : null
        const res = await this.doGetListMethod(p1, p2)
        list = res[this.listNameApi] || []
        total = res.total

        // 对[list]进行处理加工
        if (typeof this.formatList === "function") {
          list = this.formatList(list)
          console.log("[ItvElPageTable]对[list]数据进行[formatList]处理", {
            list,
          })
        }
      } catch (e) {
        console.error(e)
      }
      // console.log("[ItvElPageTable]获取表格数据", { total, list })
      this.list = list
      this.pagination.total = total
      this.isDoing = false
    },

    toggleRowExpansion(row) {
      // console.log("[toggleRowExpansion] refHwTable")
      this.$refs.refHwTable?.toggleRowExpansion?.(row)
    },
  },
}
</script>

<style scoped lang="less">
// .itv-el-page-table {
// }
</style>
