<!--
 * @Date: 2022-08-15 12:23:08
 * @LastEditTime: 2022-08-15 14:31:10
-->
<!-- 主体/选择器 HwSelectEntity hw-select-entity -->
<template>
  <ItvElSelect
    class="hw-select-entity"
    v-model="valueInner"
    v-bind="$attrs"
    :options="optionsInner"
    v-on="$listeners"
    @click="onSelector"
    placeholder="请选择服务商"
  />
</template>

<script>
import {
  mixins_ItvElFormItem_select_cache, // 外部配置: lazy noCache / 组件: valueInner onSelector 必要:doGetOptions 可选:OPTIONS_CACHE
} from "@/components/mixins/mixinsItvElInput"
import ItvElSelect from "@/components/ItvElSelect.vue"
// import { doGetSignEntityOptions } from "@/api"
import { HW_OPTIONS_CACHE_ENTITY } from "@/js/hw-js"

export default {
  name: "HwSelectEntity",
  components: { ItvElSelect },
  mixins: [mixins_ItvElFormItem_select_cache],
  props: {},
  data() {
    return {
      /** 获取选项对应的方法 */
      // doGetOptions: doGetSignEntityOptions,
      OPTIONS_CACHE: HW_OPTIONS_CACHE_ENTITY,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.hw-select-entity {
//}
</style>
