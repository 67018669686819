/*
 * @Date: 2022-05-19 13:04:41
 * @LastEditTime: 2022-08-10 17:18:15
 */
/* 封装 表单组件 需要复用的逻辑 

 */

import { consoleDev } from "@/js/hw-js"

/** 所有输入框 value 值的相关复用逻辑
 * @description (!内部 value 修改，需要手动 调用 this.$emit("input" 告知外部)
 */
const mixins_ItvElFormItem_value = {
  props: {
    /** 输入框[外部值] */
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /** 输入框[内部值] */
      valueInner: "",
    }
  },
  watch: {
    /** 监听，将[外部值]变化，同步给[内部值] */
    value: {
      handler(v) {
        if (v !== this.valueInner) {
          this.valueInner = v
        }
      },
      immediate: true,
    },
  },
}

/** 选择器[选项值][选项]逻辑
 * - #逻辑1: 支持内部自动获取设置[内部选项] (获取选项方式:中文值:全局缓存选项/接口请求)
 * - #逻辑11: 获取选项方式:全局缓存选项
 * - #逻辑111: 通过:定义[this.OPTIONS_CACHE]使用[全局缓存选项]
 * - #逻辑112: 通过:传入[noCache=false],支持不使用[缓存选项]，即强制重新请求获取 (默认:使用缓存)
 * - #逻辑12: 获取选项方式:接口请求
 * - #逻辑121: 通过:定义[this.doGetOptions]，指定接口函数
 * - #逻辑2: 支持[懒加载][内部选项]，即点击组件后，再获取[内部选项] (默认:不懒加载)
 */
const mixins_ItvElFormItem_select_cache = {
  // components: { ItvElSelect },
  mixins: [mixins_ItvElFormItem_value],
  props: {
    /** 懒加载[内部选项]  */
    lazy: {
      type: Boolean,
      default: true,
    },
    /** 不使用[全局缓存选项]，强制重新请求获取 */
    noCache: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** [内部选项] */
      optionsInner: [],
      /** 已请求过[内部选项] */
      hasRequetsOptions: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initOptionsInner()
  },
  methods: {
    /** 设置[内部选项](通过:中文值/缓存/请求) */
    initOptionsInner() {
      // 有值:立马[获取选项]
      if (this.value) {
        return this.setOptions()
      }
      // 点击后，再[获取选项]
      if (!this.lazy) {
        consoleDev(`无值,点击后，再[setOptions]`)
        return this.setOptions()
      }
    },

    /** 设置[内部选项](通过:缓存/请求) */
    setOptions() {
      if (this.hasRequetsOptions) {
        consoleDev(`已请求过选项,不再[设置选项]`)
        return
      }
      if (!this.OPTIONS_CACHE) {
        consoleDev(`有值,无缓存,[请求选项]`)
        return this.setOptionsBy_request()
      }
      if (!this.OPTIONS_CACHE.isValid) {
        consoleDev(`有值,缓存无效,[请求选项]`)
        return this.setOptionsBy_request()
      }
      if (this.noCache && !this.hasRequetsOptions) {
        consoleDev(`有值,缓存无效,[请求选项]`)
        return this.setOptionsBy_request()
      }
      consoleDev(`有值,缓存有效,使用[缓存选项]`)
      return this.setOptionsBy_cache()
    },

    /** 获取设置[内部选项](通过:缓存) */
    setOptionsBy_cache() {
      if (this.OPTIONS_CACHE) {
        this.optionsInner = this.OPTIONS_CACHE.value
        console.log(`设置选项by[cache]`, this.optionsInner)
      }
    },

    /** 获取设置[内部选项](通过:请求) */
    async setOptionsBy_request() {
      const { options, isOk } = await this.OPTIONS_CACHE.doGetOptions()

      this.hasRequetsOptions = isOk
      this.optionsInner = options
    },

    /** 点击选择器，若懒加载选项，则获取选项 */
    onSelector() {
      if (this.lazy) {
        this.setOptions()
      }
    },
  },
}

/** value 的 变化更新逻辑
 * @description  与[mixins_ItvElFormItem_value]分开写的原因:
 * 非必需
 * 有的组件需要点击选项后再更新外部值?
 */
const mixins_ItvElFormItem_onChange = {
  methods: {
    onChange_ItvElFormComponent() {
      this.$emit("input", this.valueInner) // 更新输入框[外部值]
    },
  },
}

/** 更新输入框内外值，并返回选项label文案
 * @description 应用组件: ItvElSelect.vue/ItvElRadio.vue
 * 触发场景:
 * 1 点击选项，回调change
 * 2 点击清空，也会回调change
 */
const mixins_ItvElFormItem_onOption = {
  methods: {
    onOption_ItvElFormComponent() {
      console.log("🚀 点击选项", this.valueInner)

      const value = this.valueInner
      const label = getValueLabelByOptions({
        options: this.options,
        value,
      })

      this.$emit("input", value) // 更新输入框[外部值]
      this.$emit("onChange", { value, label }) // 返回选项对应的label文案
    },
  },
}

/** 通过[options]获取[value]对应的[label]选项文案 */
function getValueLabelByOptions({ options, value } = {}) {
  let label = ""
  options.some((item) => {
    if (item.value === value) {
      label = item.label
      return true
    }
  })
  return label
}

export {
  getValueLabelByOptions,
  mixins_ItvElFormItem_value, // value(:watch) valueInner
  mixins_ItvElFormItem_onChange, // onChange_ItvElFormComponent($emit:input)
  mixins_ItvElFormItem_onOption, // onOption_ItvElFormComponent($input,$onChange)
  mixins_ItvElFormItem_select_cache, // 外部配置: lazy noCache / 组件: valueInner onSelector 必要:doGetOptions 可选:OPTIONS_CACHE
}
