<!--
 * @Date: 2022-07-12 17:21:43
 * @LastEditTime: 2022-08-16 14:49:07
-->
<!-- 日期选择器
封装: 
  - 兼容: 可传入数据类型:[DateString/Date](el原本只支持Date)
  - 兼容: 始终向外返回 Date (统一form-rule中的日期类型数据必填校验)
-->
<template>
  <div class="itv-el-date-picker dib">
    <el-date-picker
      v-if="!readonly"
      v-model="valueInner"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :class="{ 'itv-readonly': readonly }"
      :clearable="clearable"
      @change="onSelectDate"
    />
    <span v-else>{{ valueShow }}</span>
  </div>
</template>

<script>
import { showBug } from "@/js/itv-js-el"
import dayjs from "dayjs"

export default {
  name: "ItvElDatePicker",
  props: {
    /**
     * --- el组件支持的属性 ---
     * https://element.eleme.io/#/zh-CN/component/date-picker
     */
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
    /** 禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },

    // --- 组定义属性 ---
    /** [外部值] */
    value: {
      type: [String, Date, Array],
      default: "",
    },
    /** 日期选择器[显示类型] */
    type: {
      type: [String, Date],
      default: "date",
      validator: function (v) {
        return ["year", "month", "date", "week", "daterange"].includes(v)
      },
    },
    /** 可清楚 */
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [],
  data() {
    return {
      /** 输入框[内部值] */
      valueInner: "",
    }
  },
  computed: {
    /** 选项值对应的文案 */
    valueShow() {
      if (this.readonly) {
        if (this.type === "daterange") {
          const v1 = this.getComponentPickerDateStr("date", this.value?.[0])
          const v2 = this.getComponentPickerDateStr("date", this.value?.[1])
          return `${v1} 至 ${v2}`
        }
        return this.getComponentPickerDateStr(this.type, this.value)
      }
      return ""
    },
  },
  watch: {
    // 监听 输入框值 变化 外部 改 内部
    value: {
      handler(v) {
        if (v !== this.valueInner) {
          let _v = v
          // 兼容:可传入[DateString/Date]
          if (v) {
            if (typeof v === "string") {
              _v = new Date(v)
              // 校验: 能否转化为有效Date (注意: Invalid Date 也是 Date 对象，但它是无效的)
              if (isNaN(_v.getTime())) {
                _v = ""
                showBug(`传入的日期 ${v} 无法转化为Date`)
              } else {
                this.$emit("input", _v) // 更新 输入框值
              }
            } else if (Array.isArray(v) && v.length) {
              let _v1 = new Date(v[0])
              let _v2 = new Date(v[1])
              _v = [_v1, _v2]
              if (isNaN(_v1.getTime()) || isNaN(_v2.getTime())) {
                _v = []
                showBug(`传入的日期 [${v[0]}, ${v[1]}] 无法转化为Date`)
              } else {
                this.$emit("input", _v) // 更新 输入框值
              }
            }
          }
          this.valueInner = _v
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelectDate() {
      let _v = this.valueInner
      // 兼容:始终向外返回 Date
      if (typeof _v === "string") {
        _v = new Date(_v)
      }
      this.$emit("input", _v) // 更新输入框[外部值]
    },

    getComponentPickerDateStr(type, value) {
      const _v = dayjs(value)
      if (!value || !_v.isValid() || !type) {
        return this.readonly ? "-" : ""
      }
      if (type === "year") {
        return _v.format("YYYY")
      }
      if (type === "month") {
        return _v.format("YYYY-MM")
      }
      if (type === "date") {
        return _v.format("YYYY-MM-DD")
      }
      if (type === "week") {
        const _week = _v.week()
        return `${_v.format("YYYY")}第 ${_week} 周`
      }
    },
  },
}
</script>
