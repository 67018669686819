/*
 * @Date: 2022-05-07 11:29:56
 * @LastEditTime: 2022-08-04 12:36:02
 */
// 显示消息组件的封装: 无需每次都引入 message, 更简单的调用。
import { Message } from "element-ui"

function showSuccess(msg) {
  Message.success(msg)
}

function showError(msg) {
  console.log(`[showError]`, msg)
  Message.error(msg)
}

function showBug(msg) {
  console.log(`[showBug]`, msg)
  Message.error({ message: msg, duration: 0, showClose: true })
}

function showFIXME(msg) {
  showBug(`[待开发] ${msg}`)
}

export { showSuccess, showError, showBug, showFIXME }
