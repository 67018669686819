/*
 * @Date: 2022-07-30 13:17:41
 * @LastEditTime: 2022-08-16 15:03:04
 */
import Vue from "vue"

import ItvElPageFilterButton from "./ItvElPageTable/ItvElPageFilterButton.vue"
import ItvElPageTable from "./ItvElPageTable/ItvElPageTable.vue"
import ItvElPageTableH1 from "./ItvElPageTable/ItvElPageTableH1.vue"
import HwSelectEntity from "@/components/HwSelectEntity.vue"
import HwInputRatio from "./HwInputRatio.vue"
import HwSelectCompany from "./HwSelectCompany.vue"
import HwSelectJob from "./HwSelectJob.vue"
import HwSelectPayPalce from "./HwSelectPayPalce.vue"
import HwUserName from "./HwUserName.vue"
import ItvElBtnSmall from "./ItvElBtnSmall.vue"
import ItvElDatePicker from "./ItvElDatePicker.vue"
import ItvElDescription from "./ItvElDescription/ItvElDescription.vue"
import ItvElFormFooter from "./ItvElFormFooter.vue"
import ItvElImage from "./ItvElDescription/ItvElImage.vue"
import ItvElInput from "./ItvElInput.vue"
import ItvElInputSearch from "./ItvElInputSearch.vue"
import ItvElLoading from "./ItvElLoading.vue"
import ItvElPagination from "./ItvElPagination.vue"
import ItvElRadio from "./ItvElRadio.vue"
import ItvElSelect from "./ItvElSelect.vue"
import ItvElSelectRemote from "./ItvElSelectRemote.vue"
import ItvElTable from "./ItvElTable/ItvElTable.vue"
import ItvElTip from "./ItvElTip.vue"
import ItvElUploadFile from "./ItvElUploadFile.vue"
import ItvElUploadImg from "./ItvElUploadImg.vue"
import ItvElUploadXlsx from "./ItvElUploadXlsx.vue"
import ItvValueText from "./ItvElDescription/ItvValueText.vue"

export const components = {
  HwInputRatio,
  HwSelectCompany,
  HwSelectJob,
  HwSelectPayPalce,
  HwUserName,
  HwSelectEntity,
  ItvElBtnSmall,
  ItvElSelectRemote,
  ItvElDatePicker,
  ItvElDescription,
  ItvElFormFooter,
  ItvElImage,
  ItvElInput,
  ItvElInputSearch,
  ItvElLoading,
  ItvElPagination,
  ItvElRadio,
  ItvElSelect,
  ItvElTable,
  ItvElPageFilterButton,
  ItvElTip,
  ItvElUploadFile,
  ItvElUploadImg,
  ItvElUploadXlsx,
  ItvElPageTable,
  ItvElPageTableH1,
  ItvValueText,
}

// 全局注册 自定组件
Object.keys(components).forEach((key) => {
  Vue.component(components[key].name, components[key])
})
