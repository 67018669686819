/* 

useItvReuqest 封装：
[创建][axios实例]
[axios实例][拦截器]
    [axios实例]的[请求拦截处理]



itv 接口开发时，只要接口连接成功，response.code 值就是 200，并用 response.data.code 来表示请求响应结果状态码，通常 0 表示成功， 1000-1004 表示没有权限。响应失败原因通常放在 response.data.message 中



接口使用示例：
import request from 'xxx.rquest'
// 为什么返回一个request函数调用结果，而不维护一套接口的json数据？
// 因为有的接口url是动态的，比如：url中动态的id。如果接口url中无动态参数，可以写成json格式。
export function getXXX (params) {
  return request({
    url: 'xxx',
    method: 'get',
    params,
    // 拦截需要的自定义参数
  })
}
 */

/** [依赖项目npm:axios][根据项目]创建itv常用[接口请求]逻辑
1. [axios实例]拦截器
2.
(拦截器等)，并返回[axios实例]的[方法]的工具函数
调用已封装[拦截器]等的[axios实例]的[方法]的工具函数
返回[接口请求]逻辑模块
 * @param {Object} axios [axios@0.x](http://www.axios-js.com/zh-cn/docs/)库 (目的:[itv-js-lib]不依赖于该库)
 * @param {Object} p 实例的配置参数
 * @param {String} [p.baseURL] 请求接口[url前缀] (目的: ) (默认: -)
 * @param {Array} [p.codeArrNoAuth] 接口请求[失败]的[code码]数组 (默认: [])
 * @param {Number} [p.codeSuccess] 接口请求[成功]的[code码]  (默认: 0)
 * @param {Function} [p.showError] 请求失败UI提示函数 (目的: 自动报错提示) (默认: 空函数)
 * @param {Function} [p.getToken] 获取token (目的: 设置请求头参数headers.Authorization)  (默认: 空函数)
 * @param {Function} [p.clearToken] 清除缓存token (目的: 自动清除无效登录信息token) (默认: 空函数)
 * @param {Function} [p.cbNoAuth] [token无效]时的[回调] (如: 跳转登录页) (默认: 空函数)
 * @param {Number} [p.timeout] [请求超时]的毫秒数 (默认:120000, 即2分钟) (0:无超时时间)
 * @returns {Object} { request: 用于调用已封装[拦截器]等的[axios实例]的[方法]的工具函数 }
 */
function useItvReuqest(axios, p = {}) {
  if (!axios) {
    console.error("请传入[axios]库")
    return {}
  }

  const functionEmpty = () => {}
  const getSafeFun = (v) => {
    return typeof v === "function" ? v : functionEmpty
  }

  // #逻辑 [创建][axios实例] (一般1个项目就只创建1个实例)
  // #逻辑 安全处理[创建][axios实例]所需的参数

  /** 请求接口[url前缀] */
  const _baseURL = p.baseURL ?? ""
  /** 接口请求[失败]的[code码]数组 */
  let _codeArrNoAuth = p.codeArrNoAuth ?? []
  if (!Array.isArray(_codeArrNoAuth)) {
    console.error("[codeArrNoAuth]无效，应是数组类型")
    _codeArrNoAuth = []
  }
  /** 接口请求[成功]的[code码] */
  const _codeSuccess = p.codeSuccess ?? 0
  /** 请求失败UI提示函数 */
  const _showError = getSafeFun(p.showError)
  /** 获取token */
  const _getToken = getSafeFun(p.getToken)
  /** 清除缓存token */
  const _clearToken = getSafeFun(p.clearToken)
  /** [token无效]时的[回调] */
  const _cbNoAuth = getSafeFun(p.cbNoAuth)

  // #逻辑 [创建][axios实例]
  const instance = axios.create({
    // #逻辑 请求超时处理: 统一设置[axios实例]的[方法]的[请求超时]的毫秒数，默认为2分钟，可通过[timeout]参数设置
    timeout: p.timeout ?? 120000,
  })

  // #逻辑 自动报错处理: 当接口请求[失败]时，自动调用[请求失败UI提示函数]提示报错，可通过[showError]参数设置[请求失败UI提示函数]
  // 实现将[请求失败UI提示函数]与[UI组件库]解耦

  // #逻辑 将接口失败分为3种
  // ...

  // #逻辑 当接口请求成功(response.code=200)，但返回表示错误的[code码](response.data.code)，对接口请求报错的处理

  // #逻辑 [axios实例]的[请求拦截处理]
  instance.interceptors.request.use(
    (c) => {
      // #逻辑[请求拦截处理]1 若能[获取token]的值非空，则将其设置在请求头参数[headers.Authorization]上
      const token = _getToken()
      token && (c.headers.Authorization = token)

      // #逻辑[请求拦截处理]2 若[接口请求url]不是`http`开头，则设置url前缀[baseURL] (应用场景: 接口写相对路径，不通环境配置不同的服务器地址)
      if (_baseURL && !/(^(http).*$)/.test(c.url)) {
        c.baseURL = _baseURL
      }

      // 返回[axios实例]的[方法]需要的[config]参数
      return c
    },
    (err) => {
      // #逻辑 第1种错误: 网络错误 (如: 请求发送失败/超时/断网)
      _showError("网络错误")
      return Promise.reject(err)
    }
  )

  // #逻辑 [axios实例]的[响应拦截处理]
  instance.interceptors.response.use(
    (response) => {
      /** axios已扩展的[响应数据对象] */
      const res = response.data
      /** [响应数据对象]中的[code]字段 (后端定义) */
      const code = res.code
      /** [axios实例]的[方法]调用时，传入的config参数 */
      const axiosConfig = response.config
      /** 自定义的[拦截参数] */
      const interceptConfig = axiosConfig.interceptConfig || {}

      // #逻辑 [token无效]处理
      // #逻辑_1 [清除缓存token]
      // #逻辑_2 回调[cbNoAuth]执行业务登录失效的逻辑  (如: 跳转登录页)
      if (_codeArrNoAuth.includes(code)) {
        _clearToken()
        _cbNoAuth()
      }

      // #逻辑 根据接口返回[code码]判断接口请求成功或失败 (是前后端约定好的)
      // #逻辑_1 兼容: 接口没有返回[code码](如: 七牛云的接口)，直接返回响应结果`data`
      if (interceptConfig.directReturnResData) {
        return res
      }

      // #逻辑_2 若接口请求[成功]，即[code码]=[codeSuccess]，则返回直接返回`response.data.data` (目的: 省去外部对`response`的操作)
      if (code === _codeSuccess) {
        return res.data
      }

      // #逻辑_3 若接口请求[失败]，则自动进行报错提示
      const error = res.message || ""
      let isShowError = true
      if (interceptConfig.hideError) {
        // #逻辑_3_1 支持: 全部[code码]不自动报错，即[hideError]设置为`true` (应用场景: 特殊的报错逻辑)
        isShowError = false
      } else if (interceptConfig.codeArrHideError) {
        // #逻辑_3_2 支持: 部分[code码]不自动报错，即[codeArrHideError]中添加不希望报错的[code码] (应用场景: 特殊的报错逻辑)
        isShowError = !interceptConfig.codeArrHideError.includes(code)
      }

      isShowError && _showError(error || "系统错误 3")

      // #逻辑 第3种错误: 接口返回错误 (如: 传参格式不对/没有权限:1000)
      return Promise.reject(res)
    },
    (error) => {
      // #逻辑 第2种错误: 远程服务错误 (如: 接口没有找到:404/跨域)
      _showError("服务端错误")
      return Promise.reject(error)
    }
  )

  //  * @param {Boolean} c.noCode 是否根据code判断返回结果 (弃用) // 等同于 directReturnResData
  //  * @param {Boolean} showError 自动报错 (弃用) // 等同于 !hideError

  /** 用于调用已封装[拦截器]等的[axios实例]的[方法]的工具函数
   * @param {Object} c `axios参数`([axios实例]的[方法]需要的参数) + `拦截参数`([axios实例]的[拦截器]需要的自定义的参数[interceptConfig])
   * @param {String} c.url 接口的地址 (axios参数)
   * @param {String} c.method 接口的[请求方式] (取值:[get,post,put,delete,head,options]) (axios参数)
   * @param {String} [c.params] 接口的[请求参数] (默认: {}) (axios参数)
   * @param {String} [c.name] 接口描述 (默认: -) (拦截参数)
   * @param {Boolean} [c.hideError ] 不自动报错 (默认: false) (目的: 特殊的报错逻辑) (拦截参数)
   * @param {Array} [c.codeArrHideError] 不自动报错的接口返回[code码]数组 (默认: []) (目的: 特殊的报错逻辑) (拦截参数)
   * @param {Boolean} [c.noInterceptors] 不执行拦截器逻辑 (默认: -) (目的: 下载表格时，拦截器将blob字符串化，导致乱码) (拦截参数)
   * @param {Boolean} [c.directReturnResData] 不校验响应结果，直接返回响应结果`data` (默认: -) (目的: 兼容没有返回code的接口，如: 七牛云的接口) (拦截参数)
   * @returns {Promise} 调用[axios实例]的[方法]的结果
   */
  const request = (c) => {
    const url = c.url
    const method = (c.method || "").toLowerCase()

    let params = c.params || {}
    typeof params !== "object" && (params = {})

    if (c.noInterceptors) {
      // #逻辑 当不使用[axios实例]的[方法](如：axios.get)时，[接口请求参数]需要设置在参数[config.data]上
      c.data = params
      /** bugfix: post 方法，参数拼在 url 上了 (http://t.zoukankan.com/wangmaoling-p-12714584.html) */
      delete c.params
      return instance(c)

      // #todo 别的处理方式，写在拦截器中，可以思考一下是否可以优化
      // 下载表数据时后端直接返回二进制文件 https://segmentfault.com/a/1190000020861448?utm_source=sf-similar-article
      // const resType = response.config.responseType;
      // const res = response.data;
      // // 二进制文件
      // if (resType && resType !== 'json') {
      //   const filename =
      //     response.headers['content-disposition'].split('filename=')[1];
      //   return {
      //     filename,
      //     blob: res,
      //   };
      // }
    }

    // #逻辑 为了让[响应拦截器]获取[拦截参数]，需将[拦截参数]设置在[axios实例]的[方法]的参数[config]中
    /** [axios实例]的[方法]调用时，需要的[config]参数 */
    let axiosConfig = {
      /** 拦截参数 */
      interceptConfig: {
        name: c.name,
        hideError: c.hideError,
        codeArrHideError: c.codeArrHideError,
        directReturnResData: c.directReturnResData,
      },
    }

    // #逻辑 简化[axios实例]的[方法]的调用，并扩展添加[自定义的][拦截参数][interceptConfig]
    if (["get", "delete", "head", "options"].includes(method)) {
      // #逻辑 封装[axios实例]的[方法]的[接口请求参数]`data`要根据[method]类型进行不同设置的逻辑，即只需传入[params]参数作为[接口请求参数]
      // #逻辑 当请求方式为[get/delete/head/options]时，自动将[接口请求参数][params]设置在[axiosConfig.params]上，就会将[params]拼接到[请求url]的后面作为参数
      axiosConfig.params = params
      return instance[method](url, axiosConfig)
    } else {
      // #逻辑 当请求方式非[get/delete/head/options]时，[接口请求参数][params]单独传给[axios实例]的[方法]的[第2个参数]
      return instance[method](url, params, axiosConfig)
    }
  }

  return {
    request,
  }
}

export {
  useItvReuqest, // [依赖项目npm][根据项目]创建返回基于[axios]的[request]函数实例
}
