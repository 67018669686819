/*
 * @Date: 2022-05-10 11:27:09
 * @LastEditTime: 2022-07-29 12:10:34
 */
// 全屏加载中 封装: 无需每次都引入 Loading, 更简单的调用。
import { Loading } from "element-ui"

/** 加载组件 (单例) */
let loadingInstance

/** 显示 */
function showLoading() {
  loadingInstance = Loading.service({ fullscreen: true })
}

/** 隐藏 */
function hideLoading() {
  loadingInstance && loadingInstance.close()
}

export { showLoading, hideLoading }
