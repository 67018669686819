<!--
 * @Date: 2022-07-30 13:29:08
 * @LastEditTime: 2022-08-16 11:43:31
-->
/* 描述信息 ItvElDescription itv-el-description */
<template>
  <div class="itv-el-description">
    <el-descriptions :title="title" :border="border" :column="column">
      <template slot="extra">
        <slot name="extra"></slot>
      </template>
      <el-descriptions-item
        :label="item.label"
        v-for="(item, i) in infoArr"
        :key="i"
        :span="item.span || 1"
      >
        <component
          :is="item.component || 'ItvValueText'"
          :config="{ data, dataIndex: item.dataIndex }"
        ></component>
      </el-descriptions-item>
    </el-descriptions>
    <slot></slot>
  </div>
</template>

<script>
import ItvValueText from "@/components/ItvElDescription/ItvValueText.vue"
import ItvElImage from "@/components/ItvElDescription/ItvElImage.vue"
export default {
  name: "ItvElDescription",
  components: { ItvValueText, ItvElImage },
  mixins: [],
  props: {
    /** 描述[展示字段数组]
     * @description 数据结构
     *{
     *  dataIndex:  对应的[data]字段键名 (必填),
     *  label:      前端显示的字段名 (必填),
     *  span:       列的数量(选填)(默认:1),
     *  component:  对应的组件(选填)(默认:ItvValueText)(可选值:ItvElImage,ItvValueText),
     *}
     */
    infoArr: {
      type: Array,
      required: true,
    },
    /** 描述[数据] */
    data: {
      type: Object,
      required: true,
    },

    /** 描述标题 */
    title: {
      type: String,
      default: "",
    },
    /** 是否带有边框 */
    border: {
      type: Boolean,
      default: true,
    },
    /** 一行 Descriptions Item 的数量 */
    column: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.itv-el-description {
//}
</style>
