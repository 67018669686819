<!--
 * @Date: 2022-05-20 16:25:59
 * @LastEditTime: 2022-08-10 16:53:24
-->
<!-- 公司选择器 HwSelectCompany hw-select-company
实现:
  - 选中公司后，向外抛出: 公司对应[子门店/分店]选项 ([#实现1])
  - 远程搜索公司
 -->
<template>
  <ItvElSelectRemote
    class="hw-select-company"
    v-model="valueInner"
    v-bind="$attrs"
    :methodGetOptions="doGetCompanyStoreOptions"
    :placeholder="placeholder"
    :readonly="readonly"
    @onChange="onChangeCompany"
  />
</template>

<script>
import ItvElSelectRemote from "@/components/ItvElSelectRemote.vue"
import { doGetCompanyStoreOptions } from "@/api"
import { mixins_ItvElFormItem_value } from "@/components/mixins/mixinsItvElInput"

export default {
  name: "HwSelectCompany",
  components: { ItvElSelectRemote },
  mixins: [mixins_ItvElFormItem_value],
  inheritAttrs: false,
  props: {
    /** 公司对应[子门店/分店]选项 (目的:[#实现1])
     * @description 注意 storeOptions.sync 需加上 sync 才能更新外部值
     */
    storeOptions: {
      type: Array,
      default() {
        return []
      },
    },
    /** 输入框占位文本 */
    placeholder: {
      type: String,
      default: "请输入公司名称",
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    /** [子门店/分店]选项map {公司[sid]: 选项} */
    this.optionsStoreMap = {}
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /** 获取[公司]选项，并设置[子门店/分店]选项map (一次性获取全部数据) */
    async doGetCompanyStoreOptions(query) {
      let optionsCompany = []
      let optionsStoreMap = {}
      try {
        const res = await doGetCompanyStoreOptions(query)
        optionsCompany = res.optionsCompany
        optionsStoreMap = res.optionsStoreMap
        // console.log("设置[子门店/分店]选项map (查询才重置)")
        this.optionsStoreMap = optionsStoreMap
      } catch (e) {
        console.error(e)
      }
      return optionsCompany
    },
    /** 改变值 */
    onChangeCompany(c) {
      const e = this.valueInner

      // 更新外部 公司对应-门店的选项
      const optionsStore = this.optionsStoreMap?.[e] || []
      this.$emit("update:storeOptions", optionsStore)
      this.$emit("input", e)
      this.$emit("onChange", c)
    },
  },
}
</script>

<style scoped lang="less">
//.hw-select-company {
//}
</style>
