/* 用户名称 点击跳转 用户详情 HwUserName hw-user-name */
<template>
  <span class="hw-user-name">
    <router-link
      v-if="userName"
      :to="{ name: 'userDetail', query: { userId: id } }"
    >
      {{ userName }}
    </router-link>
    <span class="text-sub" v-else>(仅微信注册)</span>
  </span>
</template>

<script>
export default {
  name: "HwUserName",
  components: {},
  mixins: [],
  props: {
    row: {
      type: Object,
      default() {
        return {
          name: "err",
          id: 0,
        }
      },
    },
    name: {
      type: String,
      default: "",
    },
    userId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {}
  },
  computed: {
    userName() {
      return this.name || this.row.name || ""
    },
    id() {
      return this.userId || this.row.id || ""
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.hw-user-name {
//}
</style>
