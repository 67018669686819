// 用户信息

import { putLogin, getAdminInfo } from "@/api"
import { showSuccess } from "@/js/itv-js-el"
import { itv_getCacheToken, itv_setCacheToken } from "@/js/hw-js"

// 默认的用户数据格式 (目的: 重置为空)
const DEFAULT_ADMIN_INFO = {
  // createdAt: "", //
  // id: 0, //
  // isActive: false, //
  // updatedAt: "", //
  // username: "", //
  create_time: ":33:43", //
  last_login_ip: ".192.85.159", //
  last_login_time: ":32:56", //
  sid: "", //
  update_time: ":33:43", //
  username: "", //
}

const adminStore = {
  state() {
    return {
      token: "", // 身份令牌
      adminId: "", // 用户id
      adminInfo: { ...DEFAULT_ADMIN_INFO },
    }
  },
  getters: {
    isLogin: (state) => {
      return Boolean(state.token)
    },
  },
  mutations: {
    setUserInfo(state, v) {
      // if (!v.role) {
      //   v.role = {}
      // }
      state.adminInfo = v
    },
    setToken(state, v) {
      state.token = v
    },
    // 懒得写太多的 setState
    setState(state, { stateName, stateValue } = {}) {
      // window.consoleDev("setState", { ov: { stateName, stateValue } })
      if (stateName) {
        state[stateName] = stateValue
      }
    },
  },
  actions: {
    // 管理员 登录 并设置信息
    async doLogin(ctx, params) {
      let err = ""
      try {
        const res = await putLogin(params)
        console.log("登录", { params, res })

        const token = res.token || ""
        const adminId = res.adminId || ""

        ctx.commit("setState", { stateName: "adminId", stateValue: adminId })
        ctx.commit("setToken", token)

        ctx.dispatch("setCacheToken", token)

        showSuccess("登录成功")
      } catch (e) {
        err = e
      }
      return err // 登录失败返回错误信息
    },
    // 获取 管理员信息
    async getAdminInfo(ctx) {
      if (!ctx.state.token) {
        return console.log("未登录")
      }
      let admin
      try {
        const res = await getAdminInfo()
        // console.log("获取[管理员信息]", res)
        admin = res.admin || {}
      } catch (e) {
        console.error(e)
      }
      ctx.commit("setUserInfo", admin || DEFAULT_ADMIN_INFO)
    },
    // 获取 管理员信息 从缓存中
    getCacheToken(ctx) {
      const token = itv_getCacheToken()
      ctx.commit("setToken", token)
    },
    // 设置 管理员信息 缓存中
    setCacheToken(ctx, token) {
      itv_setCacheToken(token)
    },
  },
  modules: {},
}

export default adminStore
