/*
 * @Date: 2022-08-05 14:03:27
 * @LastEditTime: 2022-08-29 11:06:17
 */
import Cookies from "js-cookie" // 获取 cookie 中的 token
import { useItvToken } from "@/js/itv-js"
const VUE_APP_ADMIN_COOKIE = process.env.VUE_APP_ADMIN_COOKIE

const {
  /** 获取cookie中的[token] */
  itv_getCacheToken,
  /** [token]放入cookie中 */
  itv_setCacheToken,
} = useItvToken(VUE_APP_ADMIN_COOKIE, Cookies)

export {
  /** 获取cookie中的[token] */
  itv_getCacheToken,
  /** [token]放入cookie中 */
  itv_setCacheToken,
}
