import { render, staticRenderFns } from "./ItvElPageTableH1.vue?vue&type=template&id=783d629a&scoped=true&"
import script from "./ItvElPageTableH1.vue?vue&type=script&lang=js&"
export * from "./ItvElPageTableH1.vue?vue&type=script&lang=js&"
import style0 from "./ItvElPageTableH1.vue?vue&type=style&index=0&id=783d629a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783d629a",
  null
  
)

export default component.exports