/*
 * @Date: 2022-07-29 11:26:06
 * @LastEditTime: 2022-08-29 19:36:35
 */
import { itv_get_refTarget } from "../itv-js"

/** 校验 form 表单 (目的:ref需要校验，异步校验) */
function verifyForm(refName) {
  let refTarget = itv_get_refTarget.call(this, refName)
  return refTarget
    ? new Promise((resolve) => {
        refTarget.validate((valid) => {
          resolve(valid)
        })
      })
    : false
}

/** 校验某个表单项
 *  (目的:ref需要校验，异步校验)
 * @param {String} refName 表单绑定的ref
 * @param {String} itemName 校验的字段
 * @returns {Boolean} 是否通过校验
 */
function verifyFormItem(refName, itemName) {
  let refTarget = itv_get_refTarget.call(this, refName)
  return refTarget
    ? new Promise((resolve) => {
        refTarget.validateField(itemName, (errMsg) => {
          resolve(!errMsg)
        })
      })
    : false
}

/** 校验[form]表单(仔细版，返回:未通过校验的字段) */
// function verifyDetailForm(refName) {
//   let refTarget = itv_get_refTarget.call(this, refName)
//   return refTarget
//     ? new Promise((resolve) => {
//         refTarget.validate((valid, object) => {
//           // 是否校验成功
//           // 未通过校验的字段,格式如： start_date: [{"message": "请选择", "field": "start_date" }]
//           resolve({ isValid: valid, object })
//         })
//       })
//     : { isValid: false, object: {} }
// }

/** 重置 form 表单 (目的:ref需要校验) */
function resetFields(refName) {
  let refTarget = itv_get_refTarget.call(this, refName)
  refTarget && refTarget.resetFields()
  return Boolean(refTarget)
}

export { verifyForm, resetFields, verifyFormItem }
