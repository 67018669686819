/*
 * @Date: 2022-08-05 12:16:23
 * @LastEditTime: 2022-09-05 13:38:29
 */
// [dayjs@1.x](https://dayjs.fenxianglu.cn/category/#node-js)
import dayjs from "dayjs"
import { itv_formatDate2Api_constructor } from "@/js/itv-js"

/** 将日期转化为后端接口所需格式(YYYY-MM-DD) */
const itv_formatDate2Api = itv_formatDate2Api_constructor(dayjs)

/** 险金缴纳设置需要的日期格式 将 Date 转化为 YYYY-MM-01
 * @param {Date|String} date 时间
 * @returns {String} 格式化的时间 YYYY-MM-DD
 */
function getInsuranceDateStr(date) {
  return date ? dayjs(date).format("YYYY-MM-01") : ""
}

/** 获取日期对应的年月
 * @param {Date} date 日期
 * @returns {Object} {year:年,month:月}
 */
function getYearMonth(date) {
  let year = ""
  let month = ""

  if (date) {
    year = dayjs(date).year()
    month = dayjs(date).month() + 1 // 1月从0开始的
  }
  return { year, month }
}

/** 通过年:year月:month，获取日期Date
 * @param {Object} pramas 年月对象
 * @param {String} pramas.year 年
 * @param {String} pramas.month 月
 * @returns {Date} 年月对象对应的Date
 */
function getDateByYearMonth({ year, month } = {}) {
  if (!year || !month) return ""
  return dayjs()
    .year(year)
    .month(month - 1) // 1月从0开始的
    .toDate()
}

/** 根据出生年月，获取年龄 */
function getAgeByBirthday(birthday) {
  const _birthday = dayjs(birthday)
  let diffYear = "-"
  if (_birthday.isValid()) {
    diffYear = dayjs().diff(dayjs(_birthday), "year")
    diffYear = `${diffYear}岁`
  }
  return diffYear
}

/** 校验[时间数组][t0,t1,t2 ...][前后顺序]是否符合:(t0 ≥ t1 ≥ t2 ...)
 * @param {Array} dateArr 时间数组，{v:Date时间值, vZh:时间中文名称(用于错误信息提示)}
 * @returns {String} errMsg 错误信息
 */
function validateDateArrSequence(dateArr = []) {
  let err = ""
  dateArr.some((item, i) => {
    const item_next = dateArr[i + 1]
    if (item_next) {
      const dayjs_now = dayjs(item.v)
      const dayjs_next = dayjs(item_next.v)
      if (dayjs_now.isValid() && dayjs_now.isValid()) {
        const ts_now = dayjs_now.format("YYYY-MM-DD")
        const ts_next = dayjs_next.format("YYYY-MM-DD")
        const isError = dayjs(ts_next).isAfter(dayjs(ts_now))
        if (isError) {
          err = `「${item.vZh}」: ${ts_now} 应晚于 「${item_next.vZh}」: ${ts_next}`
          console.log({ dayjs_now, dayjs_next, dateArr })
          return true
        }
      } else {
        err = "时间格式错误"
        return true
      }
    }
  })
  return err
}

/** 获取[险金批扣开始][日期]
 * @param {Object} p1 险金批开始日
 * @param {Date} p1.start 开始年月
 * @param {String} p1.day 批扣日
 */
function getHwInsPayStartDate({ start, day } = {}) {
  if (start && day) {
    return dayjs(start).date(day).format("YYYY-MM-DD")
  }
  return ""
}

/** 获取[险金批扣开始][年月]
 * @param {Date} start 开始
 */
function getHwInsPayStartMonth(start) {
  if (!start) return ""
  return dayjs(start).format("YYYY-MM")
}

export {
  /** 将日期转化为后端接口所需格式(YYYY-MM-DD) */
  itv_formatDate2Api,
  // 项目[时间处理]
  getInsuranceDateStr,
  getYearMonth,
  getDateByYearMonth,
  getAgeByBirthday,
  validateDateArrSequence,
  getHwInsPayStartDate,
  getHwInsPayStartMonth,
}
