/* 加载中 ItvElLoading itv-el-loading */
<template>
  <div class="itv-el-loading" v-loading="true"></div>
</template>

<script>
export default {
  name: "ItvElLoading",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
.itv-el-loading {
  height: 100%;
  width: 100%;
  min-height: 300px;
}
</style>
