<!--
 * @Date: 2022-05-25 22:45:38
 * @LastEditTime: 2022-08-15 12:34:23
-->
/* 缴纳地选择器(全部数据获取) HwSelectPayPalce hw-select-pay-place */
<template>
  <ItvElSelect
    class="hw-select-pay-place"
    v-model="valueInner"
    v-bind="$attrs"
    :options="optionsInner"
    v-on="$listeners"
    @click="onSelector"
    placeholder="请选择"
  />
</template>

<script>
// import ItvElSelectRemote from "@/components/ItvElSelectRemote.vue"
// import { doGetPayPlaceOptions } from "@/api"
import {
  mixins_ItvElFormItem_select_cache, // 外部配置: lazy noCache / 组件: valueInner onSelector 必要:doGetOptions 可选:OPTIONS_CACHE
} from "@/components/mixins/mixinsItvElInput"
import ItvElSelect from "@/components/ItvElSelect.vue"
import { HW_OPTIONS_CACHE_PAY_PLACE } from "@/js/hw-js"

export default {
  name: "HwSelectPayPalce",
  components: { ItvElSelect },
  mixins: [mixins_ItvElFormItem_select_cache],
  inheritAttrs: false,
  props: {},
  data() {
    return {
      /** 获取选项对应的方法 */
      // doGetOptions: doGetPayPlaceOptions,
      OPTIONS_CACHE: HW_OPTIONS_CACHE_PAY_PLACE,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.hw-select-pay-place {
//}
</style>
