/*
 * @Date: 2022-05-13 11:06:54
 * @LastEditTime: 2022-11-17 14:29:50
 */

/** 常用的正则校验
 *
 * @description `ITV_REG_KEY_MAP` 目的: 更直观地管理维护支持的正则类型。
 * 其键值对象的数据格式，如下：
 * {RegExp} reg:           正则表达式
 * {String} label:         校验的内容对应的中文名称（用于报错提示）
 *
 * @description
 * phone        手机号
 * idcardno     身份证
 * email        邮箱
 */
const ITV_REG_KEY_MAP = {
  phone: {
    reg: /^([1][3,4,5,6,7,8,9])\d{9}$/,
    label: "手机号",
  },
  idcardno: {
    reg: /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/,
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    label: "身份证",
  },
  email: {
    reg: /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/,
    label: "邮箱",
  },
}

/** 常用的正则校验[数组]
 * @description 用于校验`regKey`
 */
const ITV_REG_KEY_ARR = Object.keys(ITV_REG_KEY_MAP)

/** 通用的正则校验函数
 * @param {Object} p 校验参数
 * @param {*} p.value 被校验的值
 * @param {String} [p.regKey] 正则类型 (取值:`ITV_REG_KEY_MAP`键值)
 * @param {RegExp} [p.reg] 正则表达式
 * @param {String} [p.label] 校验的内容对应的中文名称（用于报错提示）
 * @param {Boolean} [p.isNotRequired] 非必填 (应用场景: 输入才校验) (默认:false)
 * @param {String} [p.requiredMsg] 必填提示 (默认: `${label}不能为空`)
 * @returns {String} errMsg 错误原因 (为空则表示校验通过)
 *
 * @description 封装
 * - #逻辑1 校验空值，并提示
 *    - #逻辑1_1 非必填，若值为空，则不校验值
 *    - #逻辑1_2 默认必填，若值为空，则提示必填
 * - #逻辑2 用`正则`校验`value`，`正则`对应的参数`regKey`和`reg`，必须传入其一。且`regKey`优先级高于`reg`。
 *     - #逻辑2_1 校验`regKey`，通过`regKey`自动获取`reg`和`label`正则及校验信息。
 *     - #逻辑2_2 支持自定义`reg`
 * - #逻辑3 支持自定义`label`(校验的内容对应的中文名称)
 */
function itv_validateByReg(p = {}) {
  const regKey = p.regKey
  let _reg, _label
  // #逻辑2
  if (regKey) {
    // #逻辑2_1
    const p_default = ITV_REG_KEY_MAP[regKey]
    if (!p_default) {
      return `[regKey]值无效，取值: [${ITV_REG_KEY_ARR.join(", ")}]`
    }
    _reg = p_default.reg
    _label = p_default.label
  } else {
    // #逻辑2_2
    _reg = p.reg
  }

  // #逻辑3
  _label = p.label || _label || ""

  if (!_reg) {
    return "校验正则[reg]为空"
  }
  const _value = (p.value || "").trim()
  const isEmpty = _value === ""

  // #逻辑1
  if (isEmpty) {
    // #逻辑1_1 #逻辑1_2
    const msgEmpty = p.requiredMsg || `${_label}不能为空`
    return p.isNotRequired ? "" : msgEmpty
  }

  // #逻辑2
  if (!_reg.test(_value)) {
    return `${_label}不正确`
  }
  return ""
}

export {
  itv_validateByReg, // 用[正则]校验[值]
}
