// 对话框

const modalConfigDeault = {
  isShow: false, // 对话框 是否显示
  title: "", // 对话框 标题
  width: "800px", // 对话框 宽度
  dynamicComponentName: "", // 对话框 动态组件
  dynamicComponentConfig: {}, // 对话框 动态组件 的参数
}

const modalStore = {
  state() {
    return {
      // 对话框 配置
      dialogConfig: {
        ...modalConfigDeault,
      },
    }
  },
  getters: {
    isShowDialog: (state) => {
      return Boolean(state.dialogConfig.isShow)
    },
  },
  mutations: {
    // // 懒得写太多的 setState
    // setState(state, { stateName, stateValue } = {}) {
    //   // window.consoleDev("setState", { ov: { stateName, stateValue } })
    //   if (stateName) {
    //     state[stateName] = stateValue
    //   }
    // },
    setModal(state, v) {
      for (let key in state.dialogConfig) {
        state.dialogConfig[key] = v[key] ?? modalConfigDeault[key]
      }
      // console.log({ dialogConfig: state.dialogConfig })
    },
    setModalHide(state) {
      state.dialogConfig.isShow = false
    },
  },
  actions: {
    showDialog(ctx, v) {
      let dialogConfig = {
        width: v?.width,
        title: v?.title,
        dynamicComponentConfig: v?.dynamicComponentConfig || {},
        dynamicComponentName: v?.dynamicComponentName,
        isShow: true,
      }
      // console.log({ dialogConfig })
      ctx.commit("setModal", dialogConfig)
    },
    closeDialog(ctx) {
      ctx.commit("setModalHide")
    },
  },
  modules: {},
}

export default modalStore
