<!--
 * @Date: 2022-05-25 00:09:55
 * @LastEditTime: 2022-08-10 17:12:03
-->
/* 远程获取选项 选择器 ItvElSelectRemote itv-el-select-remote */
<template>
  <ItvElSelect
    class="itv-el-select-remote"
    v-model="valueInner"
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    :remoteMethod="doGetOptions"
    :readonly="readonly"
    @onChange="onChange"
  />
</template>

<script>
import ItvElSelect from "@/components/ItvElSelect.vue"
import {
  mixins_ItvElFormItem_value, // value(:watch) valueInner
} from "@/components/mixins/mixinsItvElInput"

export default {
  name: "ItvElSelectRemote",
  components: { ItvElSelect },
  mixins: [mixins_ItvElFormItem_value],
  inheritAttrs: false,
  props: {
    /** 获取选项的接口 */
    methodGetOptions: {
      type: Function,
      required: true,
    },
    /** 显示的默认选项label文案 (应用场景: 编辑时，直接展示唯一选项) */
    initLabel: {
      type: String,
      default: "",
    },
    /** 只读 */
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [], // 选项
    }
  },
  computed: {},
  watch: {
    /** 监听[initLabel]变化，设置[options]
     * @description label可能需要异步获取 ？
     */
    initLabel: {
      handler() {
        this.setOptionsBy_initLabel()
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    /** 根据[initLabel]设置[options] (条件:有选中值+选项为空) */
    setOptionsBy_initLabel() {
      const label = this.initLabel
      const value = this.value
      if (label && value && !this.options?.length) {
        this.options = [{ label, value }]
        // console.log(`传入了[initLabel]设置[options] ${label} ${this.value}`)
      }
    },

    /** 获取[options]选项 */
    async doGetOptions(query) {
      let options = []
      try {
        if (query) {
          options = await this.methodGetOptions(query)
        }
      } catch (e) {
        console.error(e)
      }
      this.options = options
    },

    onChange() {
      if (!this.valueInner) {
        this.options = []
      }
    },
  },
}
</script>
