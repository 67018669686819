<!--
 * @Date: 2022-05-12 13:51:38
 * @LastEditTime: 2022-08-26 14:47:54
-->
/* 显示 文字 ItvValueText itv-value-text (ItvElDescription 中使用) */
<template>
  <span>{{ this.value || "-" }}</span>
</template>

<script>
import { itv_get_objectValue } from "@/js/hw-js"
import {
  mixinsItvElDescriptionCtn, // config data dataIndex value
} from "@/components/ItvElDescription/mixinsItvElDescriptionCtn.js"

export default {
  name: "ItvValueText",
  components: {},
  mixins: [mixinsItvElDescriptionCtn],
  props: {},
  data() {
    return {}
  },
  computed: {
    value() {
      return itv_get_objectValue(this.data, this.dataIndex) || "-"
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
//.itv-value-text {
//}
</style>
