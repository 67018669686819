/*
 * @Date: 2022-07-18 15:45:43
 * @LastEditTime: 2022-08-26 18:05:16
 */

/** [后端接口]传参的[时间格式] */
const ITV_FORMAT_DATE_API = "YYYY-MM-DD"

/** 去除日期T字符
 * @param {String} dateStr 格式化后端返回的带T的日期
 * @returns {String} 原日期去掉T
 */
function itv_formatDateT(dateStr) {
  return (dateStr || "").replace("T", " ")
}

/** 创建[itv_formatDate2Api]函数:将日期转化为[后端接口]所需格式(YYYY-MM-DD)
 * @description [依赖项目npm:dayjs]创建[itv_formatDate2Api]函数
 * @param {Object} dayjs [dayjs@1.x](https://dayjs.fenxianglu.cn/category/query.html)时间处理库 (目的:[itv-js-lib]不依赖于该库，项目按需安装该库)
 * @returns [itv_formatDate2Api]函数: 将日期转化为[后端接口]所需格式(YYYY-MM-DD)
 */
function itv_formatDate2Api_constructor(dayjs) {
  if (!dayjs) {
    console.error("请传入 dayjs")
    return () => {}
  }

  /** 将日期转化为[后端接口]所需格式(YYYY-MM-DD)
   * @param {String|Date} date 日期
   * @returns {String} 格式为 YYYY-MM-DD 的日期
   */
  function itv_formatDate2Api(date) {
    let _v = dayjs(date)
    return date && _v.isValid() ? _v.format(ITV_FORMAT_DATE_API) : ""
  }
  return itv_formatDate2Api
}

export {
  ITV_FORMAT_DATE_API, // [后端接口]传参的[时间格式]
  itv_formatDateT, // 去除日期T字符
  itv_formatDate2Api_constructor, // 创建[itv_formatDate2Api]函数:将日期转化为[后端接口]所需格式(YYYY-MM-DD)
}
