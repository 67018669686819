/*
 * @Date: 2022-05-23 10:09:17
 * @LastEditTime: 2022-07-26 15:10:17
 */
// 复用 el-table 的props (用于 ItvElTable/ItvElTable.vue 和 ItvElPageTable.vue)

export default {
  props: {
    // 行数据的 Key
    rowKey: {
      type: [Function, String],
      default: "",
    },
    // tree-props 渲染嵌套数据的配置选项
    // { hasChildren: 'hasChildren', children: 'children' }
    treeProps: {
      type: Object,
      default() {
        return {}
      },
    },
    // 是否默认展开所有行，当 Table 包含展开行存在或者为树形表格时有效
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    // Table 的尺寸 medium / small / mini
    size: {
      type: String,
      default: "",
    },
  },
  methods: {
    rowClick(row, column, event) {
      // console.log("[row-click]", { row, column, event })
      this.$emit("row-click", row, column, event)
    },
  },
}
