<!--
 * @Date: 2022-08-16 11:44:32
 * @LastEditTime: 2022-08-16 12:04:57
-->
/* 显示 图片 (支持预览) ItvElImage itv-el-imgage */
<template>
  <span>
    <span v-if="!valueArr.length">-</span>
    <template v-if="valueArr.length">
      <el-image
        v-for="(item, i) in valueArr"
        class="img"
        :src="item"
        :key="i"
        style="width: 200px; height: 120px"
        fit="contain"
        :preview-src-list="valueArr"
      />
    </template>
  </span>
</template>

<script>
import {
  mixinsItvElDescriptionCtn, // config data dataIndex value
} from "@/components/ItvElDescription/mixinsItvElDescriptionCtn.js"

export default {
  name: "ItvElImage",
  components: {},
  mixins: [mixinsItvElDescriptionCtn],
  props: {},
  data() {
    return {
      valueArr: [],
    }
  },
  computed: {
    // previewArr() {
    //   return this.valueArr
    // },
  },
  watch: {
    value: {
      handler(v) {
        if (typeof v === "string" && v) {
          this.valueArr = [v]
        } else if (Array.isArray(v)) {
          this.valueArr = v
        } else {
          this.valueArr = []
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="less">
// .itv-el-imgage {
// }
</style>
